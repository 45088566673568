const getCurrentLayout = (state) => {
  if (state.preferencesSets.length > 0) return state.preferencesSets.filter((p) => p.isSelected === true)[0].layout.cards
  else return []
}

const getPreferencesSets = (state) => {
  return state.preferencesSets
}

const getSelectedPreferencesSet = (state) => {
  return state.preferencesSets.filter((p) => p.isSelected === true)[0]
}

export default {
  getPreferencesSets,
  getSelectedPreferencesSet,
  getCurrentLayout,
}
