<template>
  <header>
    <!-- 2Use tabs
       Each view is responsible to setup properly its tabs.

       1.- In data() there should be an array Like:
           tabs: {
             selectedTab: "insights",
             items: [
                { tab: "insights", name: this.$i18n.t("insights")},
                { tab: "status", name: this.$i18n.t("status") },
                { tab: "units", name: this.$i18n.t("units") },
             ],
           },

       2.- In "watch: " Add an entry to watch the current selected tab
           and store it in "this.tabs.selectedTab"

        2DO: Change it to app.vue childs where child has
            tabs array, then set tab

              "$store.state.auth.tabs.selectedTab"(tab) {
                 this.tabs.selectedTab = tab
              },

       3.- Setup your components render/visibility according the selected tab:
             <div v-if="tabs.selectedTab === 'status'">
               I am a conditional rendered item (ONLY when status tab is selected)
             </div>

       4.- In the mounted() lifecicle send your tabs to the header:
           this.$store.commit("setTabsConfig", this.tabs)

       OBS: After navigate out of the view the tabs will be cleared automatically
    -->
    <nav class="cq-nav">
      <div class="nav-wrapper">
        <div class="cq-header-text">
          <div class="cq-header-text-company">{{ company }}</div>
          <div class="cq-header-text-section">{{ $t($route.name) }}</div>

          <div class="cq-header-tabs" v-if="0 < availableTabs.length">
            <v-card>
              <v-tabs>
                <v-tab @click="setActive(item.tab)" v-for="item in availableTabs" :key="item.tab">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </v-card>
          </div>
        </div>

        <div class="cq-main-logo">
          <router-link to="/" id="nav-mobile">
            <img class="cq-header-logo" alt="CombiQ" />
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import Spec from "../../spec"
export default {
  mounted() {
    const tooltipElements = document.querySelectorAll(".tooltipped")
    this.tooltips = M.Tooltip.init(tooltipElements, { outDuration: 0 })
    this.$store.dispatch("preferencesHandler/retrievePreferencesSet")
  },
  data() {
    return {
      instanceModal: null,
      tooltips: null,
      account: {
        name: "",
        email: "",
      },
    }
  },

  watch: {
    "$store.state.auth.tabs.selectedTab"(tab) {
      // 2do Chk
      // console.log(tab)
      // this.activeTab = tab
    },
  },
  methods: {
    closeTooltips() {
      this.tooltips.forEach((tooltip) => {
        tooltip.close()
      })
      // 2do chk
    },
    setActive(tab) {
      this.$store.commit("setActiveTab", tab)
    },
  },

  computed: {
    availableTabs() {
      const tabsConfig = this.$store.getters.getTabsConfig
      const tabs = []

      tabsConfig.items.forEach((tab) => {
        let found = false

        this.analyticsFeatureFlags.forEach((feature) => {
          if (tab.tab === feature.tabId) {
            found = true

            if (this.$getCompanyFeatureStatus(feature.name)) {
              tabs.push(tab)
            }
          }
        })

        // 2DO validate role exclusive tabs

        if (!found) tabs.push(tab)
      })

      if (tabs.length > 0) this.setActive(tabs[0].tab)

      return tabs
    },
    company() {
      if (this.$store.getters.currentCompany) return this.$store.getters.currentCompany.companyName
      return ""
    },
    hasMoreCompanies() {
      return this.$store.getters.getUserCompanyAssignments.length > 1
    },
    analyticsFeatureFlags() {
      return Spec.COMPANY_FEATURES[2].features
    },
    preferencesSet() {
      return this.$store.getters[`preferencesHandler/getPreferencesSets`]
    },
    tooltipText() {
      return this.$t("account", [this.account.company.companyName]) + ": " + this.account.name + "<br/>" + this.account.email
    },
  },
}
</script>
<style scoped>
@import "../../assets/css/header.css";
nav #menu-icon {
  margin-left: 16px;
}
.dropdown-content {
  overflow: visible !important;
}
.dropdown-content .dropdown-content {
  left: -100% !important;
}
.cq-notifications-width {
  max-width: 62px;
}
#notifications-dropdown img {
  vertical-align: bottom;
}
nav .mnu {
  float: left;
  position: relative;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
}
.menu-item {
  text-align: center;
  cursor: pointer;
  line-height: 1 !important;
}
.dropdown-content.main-menu {
  width: 350px !important;
  height: 350px !important;
  overflow: auto;
}
.brand-logo a {
  font-size: 1.5rem;
}
.system-title {
  padding-right: 40px;
}
#dropdown-company a {
  font-size: 16px;
}
@media only screen and (max-width: 992px) {
  .dropdown-content.main-menu {
    width: 80% !important;
  }
  .system-title {
    padding-right: 16px;
  }
}
#right_margin {
  width: 30px;
}
.cq-main-logo {
  left: calc(100% - 240px);
  position: absolute;
  text-align: right;
  top: 1.2em;
  width: 240px;
}
.cq-header-text {
  color: var(--primary-color) !important;
  font-weight: 600;
  padding: 0.5em;
  text-align: center;
  width: 100%;
}
.cq-header-text-company,
.cq-header-text-section {
  width: calc(100% - 230px);
}
.cq-header-text-company {
  font-size: 1em !important;
  height: 1.8em;
}
.cq-header-text-section {
  font-size: 2em !important;
  height: 2.3em;
}
.cq-header-tabs {
  padding: 0 3.3em;
}
</style>
