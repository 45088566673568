import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const state = {
  preferencesSets: [],
  preferencesLoaded: false,
}

export default {
  namespaced: true,
  root: true,
  preserveState: true,
  state,
  actions,
  getters,
  mutations,
}
