import Api from "../../api"
import spec from "../../spec"
import store from "../store"

const state = {
  company: {
    measurementSystem: null,
    timeZone: null,
    runningTimeDataType: null,
    profile: null,
  },
  login: {
    fetching: false,
  },
  tabs: {
    selectedTab: null,
    items: [],
  },
  userContext: {
    companyEntityKey: null,
    roles: null,
    userAccess: null,
    userIdentity: null,
    userEntityKey: null,
    userPreferencesEntityKey: null,
  },
  errorLogin: "",
  currentCompanyAssignment: null,
  resetPassword: false,
  errorChangeCompany: "",
  userCompanyAssignments: [],
  expirationDate: null,
  refreshToken: null,
}

const getters = {
  isAuthenticated(state) {
    return state.userContext.userIdentity !== null
  },
  canRead: (state) => (entityName) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (userContext.roles.find((role) => role.isAdmin === true)) {
      return true
    } else {
      return userContext.roles.find((role) =>
        role.entityPermissions.some((permission) => permission.entityName === entityName && permission.canRead === true)
      )
    }
  },
  canSave: (state) => (entityName) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (userContext.roles.find((role) => role.isAdmin === true)) {
      return true
    } else {
      return userContext.roles.find((role) =>
        role.entityPermissions.some((permission) => permission.entityName === entityName && permission.canSave === true)
      )
    }
  },
  canDelete: (state) => (entityName) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (userContext.roles.find((role) => role.isAdmin === true)) {
      return true
    } else {
      return userContext.roles.find((role) =>
        role.entityPermissions.some((permission) => permission.entityName === entityName && permission.canDelete === true)
      )
    }
  },
  canAccess: (state) => (entityName) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (userContext.roles.find((role) => role.isAdmin === true)) {
      return true
    } else {
      return userContext.roles.find((role) => role.featurePermissions.some((feature) => feature === entityName))
    }
  },
  validateRoleOperation: (state) => (role) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return false
    const userContext = JSON.parse(stored)
    const userContextRole = userContext.roles[0]
    switch (userContextRole.type) {
      case spec.ROLE_TYPES.SystemDefinedSuperAdmin:
      case spec.ROLE_TYPES.SystemDefinedAdmin:
        return true
      default:
        return userContextRole.entityPermissions.find((permission) => permission.entityName === "Role" && permission.canEdit)
    }
  },
  validateUserOperation: (state) => (user) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    return parseInt(userContext.userEntityKey) === user.entityKey
  },
  getUserRole: (state) => (state) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    const role = userContext.roles.find((role) => role.companyEntityKey === store.companyEntityKey)
    return role
  },
  systemRoleHasFeaturePermission: (state, getters) => (permissionName) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (getters.superAdminOnly()) {
      return true
    } else {
      return userContext.roles.find((role) => role.featurePermissions.some((feature) => feature === permissionName))
    }
  },
  superAdminOnly: (state) => (state) => {
    const stored = localStorage.getItem("userContext")
    if (!stored) return
    const userContext = JSON.parse(stored)
    if (userContext.roles.find((role) => role.type === "SystemDefinedSuperAdmin")) {
      return true
    } else {
      return false
    }
  },
  errorMsgLogin(state) {
    return state.errorLogin
  },
  isCollisionProfileCompany() {
    // 2do set it back spec.COMPANY_PROFILE_TYPE.id
    return ["553518", "931173", "1280961", "1408041", "1740531"].includes(state.userContext.companyEntityKey)
  },
  isCompanySelected(state) {
    return state.currentCompanyAssignment != null && state.currentCompanyAssignment.token != null
  },
  isResettingPassword(state) {
    return state.resetPassword
  },
  getTabsConfig(state) {
    return state.tabs
  },
  getUserCompanyAssignments(state) {
    return state.userCompanyAssignments
  },
  currentCompany(state) {
    if (state.currentCompanyAssignment) return state.currentCompanyAssignment
    else return null
  },
  currentCompanyTimeZone(state) {
    return state.company.timeZone
  },
  errorMsgChangeCompany(state) {
    return state.errorChangeCompany
  },
  getUserCompanyUnifiedId(state) {
    if (state.userContext.userIdentity && state.currentCompanyAssignment)
      return state.userContext.userEntityKey + "/" + state.userContext.companyEntityKey
    else return null
  },
  getUserCompanyEntityKey(state) {
    if (state.userContext.userIdentity && state.currentCompanyAssignment) return state.userContext.companyEntityKey
    else return null
  },
  getUserEntityKey(state) {
    if (state.userContext.userIdentity) return state.userContext.userEntityKey
    else return null
  },
  getUserPreferencesEntityKey(state) {
    return state.userContext.userPreferencesEntityKey
  },
  currentCompanyMeasurementSystem(state) {
    return state.company.measurementSystem
  },
  currentCompanyRunningTimeDataType(state) {
    return state.company.runningTimeDataType
  },
}

const mutations = {
  authUser(state, { userAccess, userIdentity }) {
    state.userContext.userAccess = userAccess
    state.userContext.userIdentity = userIdentity
  },
  clearAuthData(state) {
    state.userContext.userAccess = null
    state.userContext.userIdentity = null
    state.userContext.roles = null
    state.userContext.userPreferencesEntityKey = null
    state.currentCompanyAssignment = null
    state.company.timeZone = null
    state.company.measurementSystem = null
    state.company.runningTimeDataType = null
  },
  errorLogin(state, errorMsg) {
    state.errorLogin = errorMsg
  },
  clearError(state) {
    state.errorLogin = null
  },
  companyPreferences(state, { measurementSystem, timeZone, runningTimeDataType }) {
    state.company.measurementSystem = measurementSystem
    state.company.timeZone = timeZone
    state.company.runningTimeDataType = runningTimeDataType
  },
  switchCompanyAssignment(state, companyAssignment) {
    state.currentCompanyAssignment = companyAssignment

    // Get User and Company entityKeys from token data
    if (companyAssignment !== null) {
      const token = companyAssignment.token.split(".")
      const tokenPayload = window.atob(token[1])
      const authInformation = JSON.parse(tokenPayload)

      state.userContext.userEntityKey = authInformation.sub
      state.userContext.companyEntityKey = authInformation.companyEntityKey
      state.userContext.roles = authInformation.roles
      localStorage.setItem("userContext", JSON.stringify(state.userContext))
    }
  },
  errorChangeCompany(state, errorMsg) {
    state.errorChangeCompany = errorMsg
  },
  ResettingPassword(state, isResetting) {
    state.resetPassword = isResetting
  },
  setActiveTab(state, tab) {
    state.tabs.selectedTab = tab
  },
  setUserPreferencesEntityKey(state, key) {
    state.userContext.userPreferencesEntityKey = key
  },
  setTabsConfig(state, tabsConfig) {
    state.tabs = tabsConfig
  },
  setUserCompanyAssignments(state, companyAssignments) {
    state.userCompanyAssignments = companyAssignments
  },
  resetTabsConfig(state) {
    state.tabs = {
      selectedTab: null,
      items: [],
    }
  },
  updateFetchStatus(state, { type, status }) {
    state[type].fetching = status
  },
  setExpirationDateState(state, expirationDate) {
    state.expirationDate = expirationDate
  },
  setRefreshTokenState(state, token) {
    state.refreshToken = token
  },
}

const actions = {
  setLogoutTimer({ commit }, expirationTime) {
    setTimeout(() => {
      commit("clearAuthData")
    }, expirationTime)
  },
  login({ commit, dispatch }, authData) {
    const type = "login"
    commit("updateFetchStatus", { type, status: true })
    return Api.userFederatedAuth
      .getToken({
        email: authData.email,
        password: authData.password,
      })
      .then((res) => {
        localStorage.setItem("userContext", JSON.stringify(res.data))
        commit("authUser", res.data)

        if (res.data.userAccess != null && res.data.userAccess.length === 1) {
          dispatch("setCompanyAssignment", res.data.userAccess[0])
        }

        commit("setUserCompanyAssignments", res.data.userAccess)
        dispatch("setRefreshToken", res.data.refreshToken)
      })
      .catch((error) => {
        // TODO: The error is not having this format.
        // Some analysis and refactoring is needed in API.js error handling.
        if (error.response) {
          if (error.response.status === 403) {
            commit("errorLogin", "error/invalid-credentials")
          }
          if (error.response.status === 401) {
            commit("errorLogin", "error/invalid-app")
          }
        } else commit("errorLogin", error.message)
      })
      .finally(() => {
        commit("updateFetchStatus", { type, status: false })
      })
  },
  tryAutoLogin({ commit, dispatch }) {
    try {
      const stored = localStorage.getItem("userContext")
      if (!stored) return
      const userContext = JSON.parse(stored)
      if (!userContext.userIdentity || !userContext.userAccess) return
      commit("authUser", userContext)
      commit("setUserCompanyAssignments", userContext.userAccess)
      const companyAssignment = localStorage.getItem("companyAssignment")
      if (!companyAssignment) return
      const companyUserAssignment = JSON.parse(companyAssignment)
      commit("switchCompanyAssignment", companyUserAssignment)
      dispatch("setCompanyPreferences")
      dispatch("setExpirationDate", companyUserAssignment.token)
      dispatch("setRefreshToken", userContext.refreshToken)
    } catch (e) {}
  },
  logout({ commit }) {
    localStorage.clear()
    commit("clearAuthData")
    window.location = "/"
  },
  setCompanyAssignment({ commit, dispatch }, companyUserAssignment) {
    localStorage.setItem("companyAssignment", JSON.stringify(companyUserAssignment))
    commit("switchCompanyAssignment", companyUserAssignment)
    dispatch("setCompanyPreferences")
    dispatch("setExpirationDate", companyUserAssignment.token)
  },
  refreshUserTokens({ commit, dispatch }) {
    const type = "login"
    commit("updateFetchStatus", { type, status: true })
    return Api.userFederatedAuth
      .refreshUserTokens()
      .then((res) => {
        localStorage.setItem("userContext", JSON.stringify(res.data))
        commit("authUser", res.data)

        if (res.data.userAccess != null) {
          if (res.data.userAccess.length === 1) dispatch("setCompanyAssignment", res.data.userAccess[0])
          else if (state.currentCompanyAssignment != null) {
            dispatch(
              "setCompanyAssignment",
              res.data.userAccess.filter(
                (access) =>
                  access.companyName === state.currentCompanyAssignment.companyName &&
                  access.companyDescription === state.currentCompanyAssignment.companyDescription
              )[0]
            )
          }

          commit("setUserCompanyAssignments", res.data.userAccess)
          dispatch("setRefreshToken", res.data.refreshToken)
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            commit("errorLogin", "error/invalid-app")
          }
        } else commit("errorLogin", error.message)
      })
      .finally(() => {
        commit("updateFetchStatus", { type, status: false })
      })
  },
  setCompanyPreferences({ commit }) {
    Api.companies
      .getById({
        _id: state.userContext.companyEntityKey,
      })
      .then((res) => {
        commit("companyPreferences", res.data)
      })
      .catch((error) => {
        this.$store.dispatch("logErrorMessage", { error, display: true })
      })
  },
  setExpirationDate({ commit }, token) {
    const tokenInformation = JSON.parse(window.atob(token.split(".")[1]))
    const difference = Math.ceil((tokenInformation.exp - tokenInformation.iat) * 0.1)
    const newExpiration = tokenInformation.exp - difference
    commit("setExpirationDateState", new Date(newExpiration * 1000))
  },
  setRefreshToken({ commit }, token) {
    commit("setRefreshTokenState", token)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
