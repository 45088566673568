<template>
  <div>
    <div id="cqMenuActivator" @click="changeVisibility()">
      <v-icon :class="getLogoSize">settings</v-icon>
      <slot></slot>
    </div>

    <div v-if="showMenu" :class="getMenuLeftPadding" @focusout="changeVisibility()" tabindex="1">
      <div>{{ email }}</div>

      <v-divider class="menu-divider"></v-divider>

      <div
        class="cq-menu-item-container"
        @click="invokeMethod(item.functionName)"
        v-for="(item, index) in cqLogoActions"
        :key="index"
        :value="index">
        <div>
          <v-icon class="cq-menu-icon">{{ item.icon }}</v-icon>
        </div>
        <div>
          {{ $t(item.itemName) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      cqLogoActions: [
        {
          enabled: !this.hasMoreCompanies,
          functionName: "/company-selector",
          icon: "fa-building",
          itemName: "company_selector",
        },
        {
          enabled: this.hasCompanyOptions,
          functionName: "/company-settings",
          icon: "fa-cog",
          itemName: "company_settings",
        },
        {
          enabled: true,
          functionName: "/profile",
          icon: "fa-cog",
          itemName: "profile_settings",
        },
        {
          enabled: true,
          functionName: "logout",
          icon: "logout",
          itemName: "logout",
        },
      ],
    }
  },
  props: {
    isMinifiedMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    company() {
      if (this.$store.getters.currentCompany) return this.$store.getters.currentCompany.companyName
      return ""
    },
    email() {
      if (this.$store.state.auth.userContext && this.$store.state.auth.userContext.userIdentity)
        return this.$store.state.auth.userContext.userIdentity.emailAddress
      return ""
    },
    getLogoSize() {
      if (this.isMinifiedMenu) return ["material-icons", "menu-icon-mini"]
      else return ["material-icons", "menu-icon"]
    },
    getMenuLeftPadding() {
      return this.isMinifiedMenu ? "cq-menu-logo-container-mini" : "cq-menu-logo-container"
    },
    hasMoreCompanies() {
      return this.$store.getters.getUserCompanyAssignments.length > 1
    },
    hasCompanyOptions() {
      return this.$store.getters.getUserCompanyAssignments.length > 1 || this.$store.getters.canRead("Company")
    },
    name() {
      if (this.$store.state.auth.userContext && this.$store.state.auth.userContext.userIdentity)
        return this.$store.state.auth.userContext.userIdentity.name
      return ""
    },
  },
  methods: {
    changeVisibility() {
      this.$nextTick(() => {
        this.showMenu = !this.showMenu
      })
    },
    invokeMethod(methodName) {
      this.showMenu = false

      if (methodName.includes("/")) this.navigateTo(methodName)
      else this[methodName]()
    },
    logout() {
      this.$store.dispatch("logout")
    },
    navigateTo(route) {
      if (this.$router.history.current.path !== route) this.$router.push({ path: route })
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.showMenu = false
      }
    })
  },
}
</script>

<style scoped>
.cq-menu-icon {
  color: var(--control-dark-color) !important;
}

.cq-menu-item-container {
  cursor: pointer;
  display: flex;
  gap: 1em;
}

.cq-menu-logo-container,
.cq-menu-logo-container-mini {
  border: 1px solid var(--control-dark-color);
  border-radius: 5px;
  background-color: var(--primary-light);
  color: var(--control-dark-color);
  display: grid;
  gap: 1em;
  padding: 1.5em 2em;
  position: absolute;
  text-align: left;
  z-index: 1000;
}

.cq-menu-logo-container {
  left: 10em;
}

.cq-menu-logo-container-mini {
  left: 4.7em;
}

.menu-divider {
  border: 1px solid var(--control-dark-color) !important;
  height: 100%;
  margin: 0em 1.3em 0 1.3em;
}
</style>
