<template>
  <!-- The vuetify tooltip has many bugs, so normal javascript/css
      hover functionality is implemented instead

      <v-tooltip right>
        <template v-slot:activator="{ on }">
            <div v-on="on">
            </div>
        </template>
        <span class="tooltip">{{ text }}</span>
    </v-tooltip>-->
  <div class="tooltip">
    <slot></slot>
    <span class="tooltiptext" v-if="showTooltip && !showPartnerName">{{ text }}</span>
    <span class="tooltiptext partnerName" v-if="showTooltip && showPartnerName"></span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Number],
      default: "",
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    showPartnerName: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped>
.partnerName:before {
  content: var(--partner-name);
}

.tooltip {
  position: unset;
  display: inline-block;
}

.tooltip .tooltiptext {
  border-radius: 3px;
  background-color: #555;
  color: #fff;
  display: flex;
  padding: 10px;
  position: absolute;
  opacity: 0.9;
  text-align: center;
  visibility: hidden;
  z-index: 10;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
