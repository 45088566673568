<template>
  <aside>
    <div id="menuControl" @click="changeMenuSize()">
      {{ isMinifiedMenu ? "&gt;&gt;" : "&lt;&lt;" }}
    </div>

    <ul id="slide-out" class="sidenav-fixed cq-background">
      <li class="cq-menu-logo-container">
        <cq-tt :showPartnerName="true" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" :to="getDashboardRoute()">
            <img alt="CombiQ" :class="getCqLogoClass" />
            <div v-if="!isMinifiedMenu" class="partnerName"></div>
          </router-link>
        </cq-tt>
      </li>

      <li>
        <cq-tt :text="$t('settings')" :showTooltip="isMinifiedMenu">
          <cq-settings-menu :isMinifiedMenu="isMinifiedMenu">
            <div v-if="!isMinifiedMenu">{{ $t("settings") }}</div>
          </cq-settings-menu>
        </cq-tt>
      </li>

      <li v-if="isInventoryVisible()">
        <div class="text-divider">{{ $t("inventory") }}</div>
      </li>

      <li v-if="$canRead('Asset')">
        <cq-tt :text="$t('assets')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/assets">
            <v-icon :class="getIconClass">mdi-engine</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("assets") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="$canRead('Unit')">
        <cq-tt :text="$t('units')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/units">
            <i :class="getIconClass">settings_remote</i>
            <div v-if="!isMinifiedMenu">{{ $t("units") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="$canRead('Company')">
        <cq-tt :text="$t('companies')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/companies">
            <v-icon :class="getIconClass">mdi-office-building</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("companies") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="devMode && $canRead('Group')">
        <cq-tt :text="$t('groups')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/groups">
            <i :class="getIconClass">layers</i>
            <div v-if="!isMinifiedMenu">{{ $t("groups") }}</div>
            <div v-if="!isMinifiedMenu">(DEV)</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="isPeopleVisible()">
        <div class="text-divider">{{ $t("people") }}</div>
      </li>

      <li v-if="$canRead('User')">
        <cq-tt :text="$t('users')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/users">
            <i :class="getIconClass">person</i>
            <div v-if="!isMinifiedMenu">{{ $t("users") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="$canRead('Role')">
        <cq-tt :text="$t('roles')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/roles">
            <v-icon :class="getIconClass">mdi-account-key</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("roles") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="$superAdminOnly()">
        <cq-tt :text="$t('system_users')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/system_users">
            <v-icon :class="getIconClass">mdi-account-hard-hat</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("system_users") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="$superAdminOnly()">
        <cq-tt :text="$t('system_roles')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/system_roles">
            <v-icon :class="getIconClass">mdi-account-key</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("system_roles") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="isMonitoringVisible()">
        <div class="text-divider">{{ $t("monitoring") }}</div>
      </li>

      <li v-if="$canRead('Notification')">
        <cq-tt :text="$t('insights')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/insights/all">
            <i :class="getIconClass">notifications</i>
            <div v-if="!isMinifiedMenu">{{ $t("insights") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <!--    2DO fix notifications.vue component
          <cq-notifications           
    :isMinifiedMenu="isMinifiedMenu"
    ref="dropdownNotifications"></cq-notifications>-->

      <li v-if="$canRead('ThresholdBase')">
        <cq-tt :text="$t('thresholds')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/thresholds">
            <i :class="getIconClass">vertical_align_top</i>
            <div v-if="!isMinifiedMenu">{{ $t("thresholds") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="isFotaVisible()">
        <div class="text-divider">{{ $t("updates") }}</div>
      </li>

      <li v-if="$systemRoleHasFeaturePermission('FotaAccess')">
        <cq-tt :text="$t('fota')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/fota">
            <i :class="getIconClass">cloud_download</i>
            <div v-if="!isMinifiedMenu">{{ $t("fota") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="devMode && $systemRoleHasFeaturePermission('FotaReportAccess')">
        <cq-tt :text="$t('report')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/fota/Report">
            <i :class="getIconClass">insert_chart</i>
            <div v-if="!isMinifiedMenu">{{ $t("report") }}</div>
          </router-link>
        </cq-tt>
      </li>

      <li v-if="isManualsVisible()">
        <div class="text-divider">{{ $t("manuals") }}</div>
      </li>

      <li v-if="$canAccess('ManualsAccess')">
        <cq-tt :text="$t('manuals')" :showTooltip="isMinifiedMenu">
          <router-link tag="a" active-class="active" to="/manuals">
            <v-icon :class="getIconClass">fa-book</v-icon>
            <div v-if="!isMinifiedMenu">{{ $t("manuals") }}</div>
          </router-link>
        </cq-tt>
      </li>
    </ul>
  </aside>
</template>
<script>
import Api from "../../api"
import SettingsMenu from "@/components/common/SettingsMenu"
// import Notifications from "@/components/common/Notifications"
import QuickTooltip from "@/components/common/QuickTooltip"
import { appConfig } from "@/config/globals"
export default {
  data() {
    return {
      devMode: appConfig.devMode,
      collapseBtnText: "",
      companySelectorInstance: null,
      preferencesSetEntityKey: null,
      isMinifiedMenu: true,
    }
  },
  components: {
    // cqNotifications: Notifications,
    cqSettingsMenu: SettingsMenu,
    cqTt: QuickTooltip,
  },
  computed: {
    getCqLogoClass() {
      return this.isMinifiedMenu ? "cq-menu-logo-mini" : "cq-menu-logo"
    },
    getIconClass() {
      if (this.isMinifiedMenu) return ["material-icons", "menu-icon-mini"]
      else return ["material-icons", "menu-icon"]
    },
  },
  methods: {
    changeMenuSize() {
      this.isMinifiedMenu = !this.isMinifiedMenu
      this.saveMenuSelection(this.isMinifiedMenu)
      // 2DO save 2db
    },
    getDashboardRoute() {
      return this.$store.getters.isCollisionProfileCompany ? "/assets" : "/"
    },
    isFotaVisible() {
      return !this.isMinifiedMenu && (this.$canRead("FotaAccess") || this.$canRead("FotaReport"))
    },
    isInventoryVisible() {
      return (
        !this.isMinifiedMenu &&
        (this.$canRead("Asset") || this.$canRead("Unit") || this.$canRead("Company") || this.$canRead("Group"))
      )
    },
    isManualsVisible() {
      return !this.isMinifiedMenu && this.$canRead("ManualsAccess")
    },
    isMonitoringVisible() {
      return !this.isMinifiedMenu && (this.$canRead("ThresholdBase") || this.$canRead("Notification"))
    },
    isPeopleVisible() {
      return !this.isMinifiedMenu && (this.$canRead("User") || this.$canRead("Role"))
    },

    loadMenuStatus() {
      for (let i = 0; i < localStorage.length; i++) {
        if (
          localStorage.key(i).includes("menuStatus") &&
          localStorage.key(i).includes(this.$store.getters.getUserCompanyUnifiedId)
        ) {
          this.isMinifiedMenu = localStorage.getItem(localStorage.key(i)) === "true"
        }
      }
    },
    saveMenuSelection(selection) {
      const key = this.$store.getters.getUserCompanyUnifiedId + "/menuStatus"
      localStorage.setItem(key, selection)
      // updatePreferenceInDB(key, selection, preferencesSetEntityKey)
    },
  },
  mounted() {
    const sideNavElem = document.querySelector(".sidenav")
    const instance = M.Sidenav.init(sideNavElem, { edge: "left", draggable: false })
    this.$store.commit("setMenuInstance", instance)
    M.Collapsible.init(this.$refs.managementMnuEl)
    if (this.hasMoreCompanies) {
      this.companySelectorInstance = M.Collapsible.init(this.$refs.companyMnuEl)
    }
    M.Collapsible.init(this.$refs.userMnuEl)
    this.loadMenuStatus()
  },
  beforeCreate() {
    Api.preferencesSet
      .getByUserId({
        _id: this.$store.getters.getUserEntityKey,
      })
      .then((res) => {
        this.preferencesSetEntityKey = res.data.entityKey

        if ("preferences" in res.data) {
          res.data.preferences.forEach((pref) => {
            if (pref.type === "General" && !pref.isDeleted) localStorage.setItem(pref.name, pref.value)
          })
        }
      })
      .catch((error) => {
        this.$store.dispatch("logErrorMessage", { error, display: false })
      })
  },
}
</script>

<style scoped>
@import "../../assets/css/sidenav.css";
li,
div {
  color: var(--primary-light) !important;
  padding: 0 0.3em;
  text-align: center !important;
  text-transform: uppercase !important;
}
.cq-menu-logo-container {
  padding-top: 1em;
}
.cq-menu-logo {
  content: var(--img-menu);
  display: inline-block;
  height: 50px;
  width: 50px;
}
.cq-menu-logo-mini {
  content: var(--img-menu);
  display: inline-block;
  height: 30px;
  width: 30px;
}
.partnerName:after {
  content: var(--partner-name);
}
.text-divider {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 3.2em;
}

.text-divider::before,
.text-divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  background-color: var(--primary-light);
  margin: 3px;
}
.menu-divider {
  border-width: 1px !important;
  border-color: var(--primary-light) !important;
  height: 100%;
}
#menuControl {
  font-family: monospace;
  font-weight: 800;
  text-align: right !important;
}
</style>
