import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/store"

// This kind of imports allows us to do lazy loading on the router routes
const Login = (resolve) => {
  require.ensure(
    ["./views/login/Login.vue"],
    () => {
      resolve(require("./views/login/Login.vue"))
    },
    "login"
  )
}
const ResetPassword = (resolve) => {
  require.ensure(
    ["./views/login/ResetPassword.vue"],
    () => {
      resolve(require("./views/login/ResetPassword.vue"))
    },
    "reset-password"
  )
}
const CompanySelector = (resolve) => {
  require.ensure(
    ["./views/login/CompanySelector.vue"],
    () => {
      resolve(require("./views/login/CompanySelector.vue"))
    },
    "company-selector"
  )
}
const CompanySettings = (resolve) => {
  require.ensure(
    ["./views/companies/CompanySettings.vue"],
    () => {
      resolve(require("./views/companies/CompanySettings.vue"))
    },
    "company-settings"
  )
}
const Dashboard = (resolve) => {
  require.ensure(
    ["./views/dashboard/Dashboard.vue"],
    () => {
      resolve(require("./views/dashboard/Dashboard.vue"))
    },
    "dashboard"
  )
}
const Assets = (resolve) => {
  require.ensure(
    ["./views/assets/Assets.vue"],
    () => {
      resolve(require("./views/assets/Assets.vue"))
    },
    "assets"
  )
}
const AssetDetails = (resolve) => {
  require.ensure(
    ["./views/assets/AssetDetails.vue"],
    () => {
      resolve(require("./views/assets/AssetDetails.vue"))
    },
    "asset-details"
  )
}
const EditAsset = (resolve) => {
  require.ensure(
    ["./views/assets/EditAsset.vue"],
    () => {
      resolve(require("./views/assets/EditAsset.vue"))
    },
    "edit_asset"
  )
}
const LinkUnits = (resolve) => {
  require.ensure(
    ["./views/assets/LinkUnits.vue"],
    () => {
      resolve(require("./views/assets/LinkUnits.vue"))
    },
    "link_units"
  )
}
const NewAsset = (resolve) => {
  require.ensure(
    ["./views/assets/NewAsset.vue"],
    () => {
      resolve(require("./views/assets/NewAsset.vue"))
    },
    "add-asset"
  )
}
const Insights = (resolve) => {
  require.ensure(
    ["./views/insights/Insights.vue"],
    () => {
      resolve(require("./views/insights/Insights.vue"))
    },
    "insights"
  )
}
const Users = (resolve) => {
  require.ensure(
    ["./views/users/Users.vue"],
    () => {
      resolve(require("./views/users/Users.vue"))
    },
    "users"
  )
}
const EditUser = (resolve) => {
  require.ensure(
    ["./components/usersSystemUsers/EditUser.vue"],
    () => {
      resolve(require("./components/usersSystemUsers/EditUser.vue"))
    },
    "edit_user"
  )
}
const LinkCompaniesToUser = (resolve) => {
  require.ensure(
    ["./views/users/LinkCompanies.vue"],
    () => {
      resolve(require("./views/users/LinkCompanies.vue"))
    },
    "link_companies"
  )
}

const Groups = (resolve) => {
  require.ensure(
    ["./views/groups/Groups.vue"],
    () => {
      resolve(require("./views/groups/Groups.vue"))
    },
    "groups"
  )
}
const EditGroup = (resolve) => {
  require.ensure(
    ["./views/groups/EditGroup.vue"],
    () => {
      resolve(require("./views/groups/EditGroup.vue"))
    },
    "edit-group"
  )
}
const Companies = (resolve) => {
  require.ensure(
    ["./views/companies/Companies.vue"],
    () => {
      resolve(require("./views/companies/Companies.vue"))
    },
    "companies"
  )
}
const EditCompany = (resolve) => {
  require.ensure(
    ["./views/companies/EditCompany.vue"],
    () => {
      resolve(require("./views/companies/EditCompany.vue"))
    },
    "edit-company"
  )
}
const EditThresholdNotifications = (resolve) => {
  require.ensure(
    ["./views/companies/EditThresholdNotifications.vue"],
    () => {
      resolve(require("./views/companies/EditThresholdNotifications.vue"))
    },
    "threshold-notifications"
  )
}
const LinkUsersToCompany = (resolve) => {
  require.ensure(
    ["./views/companies/LinkUsers.vue"],
    () => {
      resolve(require("./views/companies/LinkUsers.vue"))
    },
    "link-users"
  )
}
const Roles = (resolve) => {
  require.ensure(
    ["./views/roles/Roles.vue"],
    () => {
      resolve(require("./views/roles/Roles.vue"))
    },
    "roles"
  )
}
const EditRole = (resolve) => {
  require.ensure(
    ["./views/roles/EditRole.vue"],
    () => {
      resolve(require("./views/roles/EditRole.vue"))
    },
    "edit_role"
  )
}
const Units = (resolve) => {
  require.ensure(
    ["./views/units/Units.vue"],
    () => {
      resolve(require("./views/units/Units.vue"))
    },
    "units"
  )
}
const SensorAnalytics = (resolve) => {
  require.ensure(
    ["./components/units/SensorAnalytics.vue"],
    () => {
      resolve(require("./components/units/SensorAnalytics.vue"))
    },
    "sensor_analytics"
  )
}
const UnitDetails = (resolve) => {
  require.ensure(
    ["./views/units/UnitDetails.vue"],
    () => {
      resolve(require("./views/units/UnitDetails.vue"))
    },
    "unit_details"
  )
}
const UnitConfig = (resolve) => {
  require.ensure(
    ["./views/units/UnitConfig.vue"],
    () => {
      resolve(require("./views/units/UnitConfig.vue"))
    },
    "unit_config"
  )
}
const LinkAsset = (resolve) => {
  require.ensure(
    ["./views/units/LinkAsset.vue"],
    () => {
      resolve(require("./views/units/LinkAsset.vue"))
    },
    "link_asset"
  )
}
const UnifiedThresholds = (resolve) => {
  require.ensure(
    ["./views/thresholds/Thresholds.vue"],
    () => {
      resolve(require("./views/thresholds/Thresholds.vue"))
    },
    "thresholds"
  )
}
const AddUnifiedThreshold = (resolve) => {
  require.ensure(
    ["./views/thresholds/AddUnifiedThreshold.vue"],
    () => {
      resolve(require("./views/thresholds/AddUnifiedThreshold.vue"))
    },
    "add_threshold"
  )
}
const LinkUnitThresholds = (resolve) => {
  require.ensure(
    ["./views/units/LinkThresholds.vue"],
    () => {
      resolve(require("./views/units/LinkThresholds.vue"))
    },
    "link_unit_thresholds"
  )
}
const DebugUnit = (resolve) => {
  require.ensure(
    ["./views/units/DebugUnit.vue"],
    () => {
      resolve(require("./views/units/DebugUnit.vue"))
    },
    "debug"
  )
}
const EditUnit = (resolve) => {
  require.ensure(
    ["./views/units/EditUnit.vue"],
    () => {
      resolve(require("./views/units/EditUnit.vue"))
    },
    "units"
  )
}
const NotFound = (resolve) => {
  require.ensure(
    ["./views/404.vue"],
    () => {
      resolve(require("./views/404.vue"))
    },
    "not-found"
  )
}

const Fota = (resolve) => {
  require.ensure(
    ["./views/fota/Files.vue"],
    () => {
      resolve(require("./views/fota/Files.vue"))
    },
    "Fota"
  )
}

const Report = (resolve) => {
  require.ensure(
    ["./views/fota/Report.vue"],
    () => {
      resolve(require("./views/fota/Report.vue"))
    },
    "Report"
  )
}

const Manuals = (resolve) => {
  require.ensure(
    ["./views/manuals/Manuals.vue"],
    () => {
      resolve(require("./views/manuals/Manuals.vue"))
    },
    "Manuals"
  )
}

const Profile = (resolve) => {
  require.ensure(
    ["./views/users/Profile.vue"],
    () => {
      resolve(require("./views/users/Profile.vue"))
    },
    "profile"
  )
}

const Forbidden = (resolve) => {
  require.ensure(
    ["./views/403.vue"],
    () => {
      resolve(require("./views/403.vue"))
    },
    "forbidden"
  )
}
const EditThresholds = (resolve) => {
  require.ensure(
    ["./views/thresholds/AddUnifiedThreshold.vue"],
    () => {
      resolve(require("./views/thresholds/AddUnifiedThreshold.vue"))
    },
    "thresholds"
  )
}
const UnitSettings = (resolve) => {
  require.ensure(
    ["./views/units/Units.vue"],
    () => {
      resolve(require("./views/units/Units.vue"))
    },
    "unitsettings"
  )
}
const LinkUserRoles = (resolve) => {
  require.ensure(
    ["./views/users/LinkRoles.vue"],
    () => {
      resolve(require("./views/users/LinkRoles.vue"))
    },
    "link_user_roles"
  )
}
const LinkRoleUsers = (resolve) => {
  require.ensure(
    ["./views/roles/LinkUsers.vue"],
    () => {
      resolve(require("./views/roles/LinkUsers.vue"))
    },
    "link_role_users"
  )
}
const SystemRoles = (resolve) => {
  require.ensure(
    ["./views/systemroles/SystemRoles.vue"],
    () => {
      resolve(require("./views/systemroles/SystemRoles.vue"))
    },
    "system_roles"
  )
}
const LinkSystemRoleUsers = (resolve) => {
  require.ensure(
    ["./views/systemroles/LinkUsers.vue"],
    () => {
      resolve(require("./views/systemroles/LinkUsers.vue"))
    },
    "link_system_role_users"
  )
}
const SystemUsers = (resolve) => {
  require.ensure(
    ["./views/systemusers/SystemUsers.vue"],
    () => {
      resolve(require("./views/systemusers/SystemUsers.vue"))
    },
    "system_users"
  )
}
const LinkSystemUserRoles = (resolve) => {
  require.ensure(
    ["./views/systemusers/LinkRoles.vue"],
    () => {
      resolve(require("./views/systemusers/LinkRoles.vue"))
    },
    "link_system_user_roles"
  )
}
const EditCompanyFeatures = (resolve) => {
  require.ensure(
    ["./views/companies/CompanyFeatures.vue"],
    () => {
      resolve(require("./views/companies/CompanyFeatures.vue"))
    },
    "edit-company-features"
  )
}
const CustomUoms = (resolve) => {
  require.ensure(
    ["./views/companies/CustomUoms.vue"],
    () => {
      resolve(require("./views/companies/CustomUoms.vue"))
    },
    "custom-uoms"
  )
}

export const routes = [
  {
    path: "/",
    name: "home",
    components: {
      default: Dashboard,
      login: Login,
      company: CompanySelector,
      resetPassword: ResetPassword,
    },
  },
  {
    path: "/company-selector",
    name: "company_selector",
    component: CompanySelector,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Company")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },

  {
    path: "/company-settings",
    name: "company_settings",
    component: CompanySettings,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Company")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/assets",
    name: "assets",
    component: Assets,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Asset")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/assets/details/:id",
    name: "asset_details",
    component: AssetDetails,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Asset")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/assets/edit/:id",
    name: "edit_asset",
    component: EditAsset,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Asset")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/assets/:id/units",
    name: "link_units",
    component: LinkUnits,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await (store.getters.canSave("Asset") && store.getters.canSave("Unit"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/assets/add",
    name: "add-asset",
    component: NewAsset,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Asset")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/insights/:id",
    name: "insights",
    component: Insights,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Notification")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("User")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/users/edit/:id",
    name: "edit_user",
    component: EditUser,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("User")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/users/:id/companies/",
    name: "link_companies",
    component: LinkCompaniesToUser,
    beforeEnter: (to, from, next) => {
      // ... permission check
      next()
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: Groups,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Group")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/groups/edit/:id",
    name: "edit-group",
    component: EditGroup,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Group")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: Companies,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Company")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/companies/:id/edit",
    name: "edit_company",
    component: EditCompany,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Company")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/companies/:id/threshold-notifications/",
    name: "threshold_notifications",
    component: EditThresholdNotifications,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Notification")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/companies/:id/users/",
    name: "link_users",
    component: LinkUsersToCompany,
    beforeEnter: (to, from, next) => {
      // ... permission check
      next()
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: Roles,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Role")
        if (hasPermission) {
          next()
          return
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/roles/edit/:id",
    name: "edit_role",
    component: EditRole,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Role")
        if (hasPermission) {
          next()
          return
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units",
    name: "units",
    component: Units,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Unit")
        if (hasPermission) {
          next()
          return
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/:id/settings",
    name: "unitsettings",
    component: UnitSettings,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canAccess("ModifyUnitChargingSettings")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/:id/assets",
    name: "link_asset",
    component: LinkAsset,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await (store.getters.canSave("Asset") && store.getters.canSave("Unit"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/thresholds",
    name: "thresholds",
    component: UnifiedThresholds,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("ThresholdBase")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/thresholds/create",
    name: "add_threshold",
    component: AddUnifiedThreshold,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("ThresholdBase")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/thresholds/edit/:id",
    name: "edit-threshold",
    component: AddUnifiedThreshold,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("ThresholdBase")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/edit/:id",
    name: "edit_unit",
    component: EditUnit,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Unit")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },

  // 2do update correctly
  {
    path: "/units_XX/:id",
    name: "sensor_analytics",
    component: SensorAnalytics,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = (await store.getters.canAccess("DataAnalyticsAccess")) && (await store.getters.canRead("Unit"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },

  {
    path: "/units/:id",
    name: "unit_details",
    component: UnitDetails,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = (await store.getters.canAccess("DataAnalyticsAccess")) && (await store.getters.canRead("Unit"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/:id/config",
    name: "device_settings",
    component: UnitConfig,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canRead("Unit")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/:id/thresholds",
    name: "link_unit_thresholds",
    component: LinkUnitThresholds,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await (store.getters.canSave("Unit") && store.getters.canSave("ThresholdBase"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/units/:id/debug",
    name: "debug_unit",
    component: DebugUnit,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.systemRoleHasFeaturePermission("DebugInformation")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/fota",
    name: "fota",
    component: Fota,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.systemRoleHasFeaturePermission("FotaAccess")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/fota/report",
    name: "report",
    component: Report,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.systemRoleHasFeaturePermission("FotaAccess")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/profile",
    name: "profile_settings",
    component: Profile,
    beforeEnter: (to, from, next) => {
      // ... permission check
      next()
    },
  },
  {
    path: "/manuals",
    name: "manuals",
    component: Manuals,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canAccess("ManualsAccess")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  { path: "/404", name: "not-found", component: NotFound },
  { path: "*", redirect: "/404" },
  { path: "/403", name: "forbidden", component: Forbidden },
  {
    path: "/machines",
    redirect: { name: "assets" },
  },
  {
    path: "/thresholds/edit/:id",
    name: "edit-thresholds",
    component: EditThresholds,
    beforeEnter: (to, from, next) => {
      // ... permission check
      next()
    },
  },
  {
    path: "/users/:id/roles",
    name: "link_user_roles",
    component: LinkUserRoles,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await (store.getters.canSave("User") && store.getters.canSave("Role"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/roles/:id/users",
    name: "link_role_users",
    component: LinkRoleUsers,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await (store.getters.canSave("Role") && store.getters.canSave("User"))
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/system_roles",
    name: "system_roles",
    component: SystemRoles,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/system_roles/:id/users",
    name: "link_system_role_users",
    component: LinkSystemRoleUsers,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/system_users",
    name: "system_users",
    component: SystemUsers,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/system-users/edit/:id",
    name: "edit_system_user",
    component: EditUser,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/system-users/:id/roles",
    name: "link_system_user_roles",
    component: LinkSystemUserRoles,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/company-features/:id",
    name: "edit_company_features",
    component: EditCompanyFeatures,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.superAdminOnly()
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
  {
    path: "/custom-uoms",
    name: "custom_uoms",
    component: CustomUoms,
    async beforeEnter(to, from, next) {
      try {
        const hasPermission = await store.getters.canSave("Company")
        if (hasPermission) {
          next()
        } else {
          next({
            name: "forbidden",
          })
        }
      } catch (e) {
        next({
          name: "forbidden",
        })
      }
    },
  },
]
const router = new VueRouter({ mode: "history", routes })
router.afterEach((to, from) => {
  if (store.state.materialize.menuInstance) store.state.materialize.menuInstance.close()
})
Vue.use(VueRouter)
export default router
