// Unified data types. These will be the only datatypes once the system is prepared to used them.
const DT_NOT_APPLICABLE = 24
const DT_UN_ACCELERATION = 25
const DT_UN_ACCELERATION_FREQUENCY_SPECTRUM = 26
const DT_UN_COMPENSATED_PRESSURE = 27
const DT_UN_DISPLACEMENT = 28
const DT_UN_DISPLACEMENT_FREQUENCY_SPECTRUM = 29
const DT_UN_PRESSURE = 30
const DT_UN_RAW_RPM = 31
const DT_UN_RESISTANCE = 32
const DT_UN_SOUND_FREQUENCY_SPECTRUM = 34
const DT_UN_ACTIVE_CONNECTIONS = 35
const DT_UN_DISCOVERED_DEVICES = 36
const DT_UN_FAILED_COMMUNICATIONS = 37
const DT_UN_FAILED_POSTS = 38
const DT_UN_REBOOT = 39
const DT_UN_RSSI = 40
const DT_UN_UPLOAD_DOWNLOAD_BYTES = 41
const DT_UN_UPLOAD_DOWNLOAD_DROPPED = 42
const DT_UN_UPLOAD_DOWNLOAD_ERRORS = 43
const DT_UN_UPLOAD_SPEED = 44
const DT_UN_UPTIME = 45
const DT_UN_USAGE = 46
const DT_UN_TEMPERATURE = 47
const DT_UN_TEST_DATA = 48
const DT_UN_VELOCITY = 49
const DT_UN_VELOCITY_FREQUENCY_SPECTRUM = 50
const DT_UN_VOLTAGE = 51
const DT_UN_STROKES = 52
const DT_UN_DERIVATIVE_ACCELERATION = 53
const DT_ELECTRIC_CURRENT = 54
const DT_RELATIVE_HUMIDITY = 55
const DT_ELECTRIC_POWER = 56
const DT_SWITCH = 57
const DT_UN_SOUND = 58
const DT_UN_ENERGY = 59
const DT_HOURS = 9000
const DT_MINUTES = 9001
const DT_RT_G = 9002
const DT_PERCENTAGE = 9003

const MS_INTERNATIONAL = 0
const MS_IMPERIAL = 1

const MS_VALUE_UOM = 0
const MS_VALUE = 1
const MS_UOM = 2
const MS_ORIGINAL_VALUE_UOM = 3

const THRESHOLD_TYPES = {
  SinglePoint: 1,
  MultiPoint: 2,
  RunningTime: 3,
  ChargingSettings: 4,
  LowBattery: 5,
  LostConnection: 6,
}

const DATA_TYPES = {
  [DT_UN_TEMPERATURE]: {
    color: { r: 255, g: 110, b: 0 },
    text: "form.input.sensor.temperature",
    name: "Temperature",
  },
  [DT_UN_SOUND]: {
    color: { r: 148, g: 108, b: 172 },
    text: "form.input.sensor.sound_pressure",
    name: "Sound",
  },
  [DT_UN_ACCELERATION]: {
    color: { r: 21, g: 49, b: 253 },
    text: "form.input.sensor.acceleration",
    name: "Acceleration",
  },
  [DT_UN_VOLTAGE]: {
    color: { r: 160, g: 100, b: 192 },
    text: "form.input.sensor.voltage",
    name: "Voltage",
  },
  [DT_UN_RESISTANCE]: {
    color: { r: 235, g: 239, b: 0 },
    text: "form.input.sensor.resistance",
    name: "Resistance",
  },
  [DT_UN_VELOCITY]: {
    color: { r: 235, g: 1, b: 253 },
    text: "form.input.sensor.velocity",
    name: "Velocity",
  },
  [DT_UN_PRESSURE]: {
    color: { r: 139, g: 227, b: 182 },
    text: "form.input.sensor.pressure",
    name: "Pressure",
  },
  [DT_RELATIVE_HUMIDITY]: {
    color: { r: 50, g: 10, b: 0 },
    text: "form.input.sensor.relative_humidity",
    name: "RelativeHumidity",
  },
  [DT_UN_COMPENSATED_PRESSURE]: {
    color: { r: 11, g: 20, b: 251 },
    text: "form.input.sensor.compensated_pressure",
    name: "CompensatedPressure",
  },
  [DT_UN_ACCELERATION_FREQUENCY_SPECTRUM]: {
    color: { r: 167, g: 93, b: 7 },
    text: "form.input.sensor.acceleration",
    name: "AccelerationFrequencySpectrum",
  },
  [DT_ELECTRIC_CURRENT]: {
    color: { r: 79, g: 20, b: 0 },
    text: "form.input.sensor.electric_current",
    name: "ElectricCurrent",
  },
  [DT_ELECTRIC_POWER]: {
    color: { r: 150, g: 255, b: 0 },
    text: "form.input.sensor.electric_power",
    name: "ElectricPower",
  },
  [DT_SWITCH]: {
    color: { r: 150, g: 255, b: 0 },
    text: "form.input.sensor.switch",
  },
  [DT_UN_ENERGY]: {
    color: { r: 200, g: 85, b: 85 },
    text: "form.input.sensor.energy",
  },
  [DT_UN_UPTIME]: {
    text: "form.input.sensor.uptime",
    name: "Uptime",
  },
  [DT_UN_ACTIVE_CONNECTIONS]: {
    text: "form.input.sensor.active_connections",
    name: "ActiveConnections",
  },
  [DT_UN_FAILED_POSTS]: {
    text: "form.input.sensor.failed_posts",
    name: "FailedPosts",
  },
  [DT_UN_DISCOVERED_DEVICES]: {
    text: "form.input.sensor.discovered_devices",
    name: "DiscoveredDevices",
  },
  [DT_UN_REBOOT]: {
    text: "form.input.sensor.reboot",
    name: "Reboot",
  },
  [DT_UN_USAGE]: {
    text: "form.input.sensor.usage",
    name: "Usage",
  },
  [DT_UN_UPLOAD_DOWNLOAD_BYTES]: {
    text: "form.input.sensor.upload_download_bytes",
    name: "UploadDownloadBytes",
  },
  [DT_UN_UPLOAD_DOWNLOAD_ERRORS]: {
    text: "form.input.sensor.upload_download_errors",
    name: "UploadDownloadErrors",
  },
  [DT_UN_UPLOAD_DOWNLOAD_DROPPED]: {
    text: "form.input.sensor.upload_download_dropped",
    name: "UploadDownloadDropped",
  },
  [DT_UN_RSSI]: {
    text: "form.input.sensor.rssi",
    name: "RSSI",
  },
  [DT_UN_UPLOAD_SPEED]: {
    text: "form.input.sensor.upload_speed",
    name: "UploadSpeed",
  },
  [DT_UN_STROKES]: {
    text: "form.input.sensor.strokes",
    name: "Strokes",
  },
  [DT_UN_ENERGY]: {
    text: "energy_consumption_chart_label",
    name: "Energy",
  },
}

const SINGLE_POINT_DATA_TYPES = [
  { id: 25, name: "form.input.sensor.acceleration", codeName: "Acceleration" },
  {
    id: 27,
    name: "form.input.sensor.compensated_pressure",
    codeName: "CompensatedPressure",
  },
  { id: 32, name: "form.input.sensor.resistance", codeName: "Resistance" },
  {
    id: 35,
    name: "form.input.sensor.active_connections",
    codeName: "ActiveConnections",
  },
  {
    id: 36,
    name: "form.input.sensor.discovered_devices",
    codeName: "DiscoveredDevices",
  },
  {
    id: 37,
    name: "form.input.sensor.failed_communications",
    codeName: "FailedCommunications",
  },
  { id: 38, name: "form.input.sensor.failed_posts", codeName: "FailedPosts" },
  { id: 39, name: "form.input.sensor.reboot", codeName: "Reboot" },
  { id: 40, name: "form.input.sensor.rssi", codeName: "RSSI" },
  {
    id: 41,
    name: "form.input.sensor.upload_download_bytes",
    codeName: "UploadDownloadBytes",
  },
  {
    id: 42,
    name: "form.input.sensor.upload_download_dropped",
    codeName: "UploadDownloadDropped",
  },
  {
    id: 43,
    name: "form.input.sensor.upload_download_errors",
    codeName: "UploadDownloadErrors",
  },
  { id: 44, name: "form.input.sensor.upload_speed", codeName: "UploadSpeed" },
  { id: 45, name: "form.input.sensor.uptime", codeName: "Uptime" },
  { id: 46, name: "form.input.sensor.usage", codeName: "Usage" },
  { id: 47, name: "form.input.sensor.temperature", codeName: "Temperature" },
  { id: 49, name: "form.input.sensor.velocity", codeName: "Velocity" },
  { id: 51, name: "form.input.sensor.voltage", codeName: "Voltage" },
  { id: 52, name: "form.input.sensor.strokes", codeName: "Strokes" },
  {
    id: 53,
    name: "form.input.sensor.derivate_of_acceleration",
    codeName: "DerivateOfAcceleration",
  },
  { id: 54, name: "form.input.sensor.electric_current", codeName: "ElectricCurrent" },
  { id: 55, name: "form.input.sensor.relative_humidity", codeName: "RelativeHumidity" },
  { id: 56, name: "form.input.sensor.electric_power", codeName: "ElectricPower" },
  { id: 57, name: "form.input.sensor.switch", codeName: "Switch" },
  { id: 58, name: "form.input.sensor.sound", codeName: "Sound" },
  { id: 59, name: "form.input.sensor.energy", codeName: "Energy" },
]

const MULTI_POINT_DATA_TYPES = [
  { id: 25, name: "form.input.sensor.acceleration", codeName: "Acceleration" },
  {
    id: 26,
    name: "form.input.sensor.acceleration_frequency_spectrum",
    codeName: "AccelerationFrequencySpectrum",
  },
  { id: 28, name: "form.input.sensor.displacement", codeName: "Displacement" },
  {
    id: 29,
    name: "form.input.sensor.displacement_frequency_spectrum",
    codeName: "DisplacementFrequencySpectrum",
  },
  { id: 30, name: "form.input.sensor.pressure", codeName: "Pressure" },
  { id: 31, name: "form.input.sensor.raw_rpm", codeName: "RawRpm" },
  {
    id: 34,
    name: "form.input.sensor.sound_frequency_spectrum",
    codeName: "SoundFrequencySpectrum",
  },
  { id: 48, name: "form.input.sensor.test_data", codeName: "TestData" },
  { id: 49, name: "form.input.sensor.velocity", codeName: "Velocity" },
  {
    id: 50,
    name: "form.input.sensor.velocity_frequency_spectrum",
    codeName: "VelocityFrequencySpectrum",
  },
  {
    id: 53,
    name: "form.input.sensor.derivate_of_acceleration",
    codeName: "DerivateOfAcceleration",
  },
  { id: 58, name: "form.input.sensor.sound", codeName: "Sound" },
  { id: 59, name: "form.input.sensor.energy", codeName: "Energy" },
]

const RUNNING_TIME_DATA_TYPES = [{ id: 24, name: "form.input.sensor.not_applicable", codeName: "NotApplicable" }]

const CHARGING_SETTINGS_DATA_TYPES = [{ id: 24, name: "form.input.sensor.not_applicable", codeName: "NotApplicable" }]

const LOW_BATTERY_DATA_TYPES = [{ id: 51, name: "form.input.sensor.voltage", codeName: "Voltage" }]

const LOST_CONNECTION_DATA_TYPES = [{ id: 24, name: "form.input.sensor.not_applicable", codeName: "NotApplicable" }]

const DATA_MAPPING = {
  NotApplicable: DT_NOT_APPLICABLE,
  Temperature: DT_UN_TEMPERATURE,
  Sound: DT_UN_SOUND,
  Acceleration: DT_UN_ACCELERATION,
  Voltage: DT_UN_VOLTAGE,
  Resistance: DT_UN_RESISTANCE,
  Velocity: DT_UN_VELOCITY,
  Pressure: DT_UN_PRESSURE,
  RelativeHumidity: DT_RELATIVE_HUMIDITY,
  CompensatedPressure: DT_UN_COMPENSATED_PRESSURE,
  Displacement: DT_UN_DISPLACEMENT,
  AccelerationFrequencySpectrum: DT_UN_ACCELERATION_FREQUENCY_SPECTRUM,
  DerivateOfAcceleration: DT_UN_DERIVATIVE_ACCELERATION,
  ElectricCurrent: DT_ELECTRIC_CURRENT,
  ElectricPower: DT_ELECTRIC_POWER,
  Switch: DT_SWITCH,
  Energy: DT_UN_ENERGY,
  Uptime: DT_UN_UPTIME,
  ActiveConnections: DT_UN_ACTIVE_CONNECTIONS,
  FailedPosts: DT_UN_FAILED_POSTS,
  DiscoveredDevices: DT_UN_DISCOVERED_DEVICES,
  Reboot: DT_UN_REBOOT,
  Usage: DT_UN_USAGE,
  UploadDownloadBytes: DT_UN_UPLOAD_DOWNLOAD_BYTES,
  UploadDownloadErrors: DT_UN_UPLOAD_DOWNLOAD_ERRORS,
  UploadDownloadDropped: DT_UN_UPLOAD_DOWNLOAD_DROPPED,
  RSSI: DT_UN_RSSI,
  UploadSpeed: DT_UN_UPLOAD_SPEED,
}

const MS_MAPPING = {
  International: MS_INTERNATIONAL,
  Imperial: MS_IMPERIAL,
}

/*
  Deprecated values:
  1 : Ambient
  4 : Internal 3
  33 - 35 : External accelerometers
  49 : MCU voltage
  65 - 67: External pressure sensors
*/
const SENSOR_TYPES = {
  0: {
    label: "Internal temperature sensor",
    location: "Asset surface",
    name: "form.input.sensorTypes.internal_temperature_sensor",
    codeName: "InternalTemperatureSensor",
  },
  2: {
    label: "Development test sensor",
    location: undefined,
    name: "form.input.sensorTypes.development_test_sensor",
    codeName: "DevelopmentTestSensor",
  },
  3: {
    label: "MCU",
    location: "Internal MCU",
    name: "form.input.sensorTypes.mcu",
    codeName: "MCU",
  },
  8: {
    label: "External Port 1",
    location: "Octopus",
    name: "form.input.sensorTypes.external_port_1",
    codeName: "ExternalPort1",
  },
  9: {
    label: "External Port 2",
    location: "Octopus",
    name: "form.input.sensorTypes.external_port_2",
    codeName: "ExternalPort2",
  },
  10: {
    label: "External Port 3",
    location: "Octopus",
    name: "form.input.sensorTypes.external_port_3",
    codeName: "ExternalPort3",
  },
  16: {
    label: "Internal microphone",
    location: "Asset Surface",
    name: "form.input.sensorTypes.internal_microphone",
    codeName: "InternalMicrophone",
  },
  32: {
    label: "Internal accelerometer",
    location: "Asset Surface",
    name: "form.input.sensorTypes.internal_accelerometer",
    codeName: "InternalAccelerometer",
  },
  48: {
    label: "Internal battery meter",
    location: "Internal battery",
    name: "form.input.sensorTypes.internal_battery_meter",
    codeName: "InternalBatteryMeter",
  },
  64: {
    label: "Internal pressure sensor",
    location: "Asset surface",
    name: "form.input.sensorTypes.internal_pressure_sensor",
    codeName: "InternalPressureSensor",
  },
  68: {
    label: "Internal Humidity Sensor",
    location: "Asset surface",
    name: "form.input.sensorTypes.internal_humidity_sensor",
    codeName: "InternalHumiditySensor",
  },
  80: {
    label: "System information",
    location: "Internal",
    name: "form.input.sensorTypes.system_information",
    codeName: "System_Information",
  },
  81: {
    label: "Disk",
    location: "Internal",
    name: "form.input.sensorTypes.system_disk",
    codeName: "System_Disk",
  },
  82: {
    label: "RAM",
    location: "Internal",
    name: "form.input.sensorTypes.system_ram",
    codeName: "System_RAM",
  },
  83: {
    label: "Ethernet",
    location: "Internal",
    name: "form.input.sensorTypes.system_ethernet",
    codeName: "System_Ethernet",
  },
  84: {
    label: "WiFi",
    location: "Internal",
    name: "form.input.sensorTypes.system_wifi",
    codeName: "System_WiFi",
  },
  85: {
    label: "Bluetooth",
    location: undefined,
    name: "form.input.sensorTypes.system_bluetooth",
    codeName: "System_Bluetooth",
  },
  86: {
    label: "System Mobile",
    location: undefined,
    name: "form.input.sensorTypes.system_mobile",
    codeName: "System_Mobile",
  },
  96: {
    label: "Event Counter",
    location: undefined,
    name: "form.input.sensorTypes.aggregation_event_counter",
    codeName: "Aggregation_EventCounter",
  },
  256: {
    label: "NotApplicable",
    location: undefined,
    name: "form.input.sensorTypes.not_applicable",
    codeName: "Not_Applicable",
  },
}

const NOT_APPLICABLE_SENSOR_TYPE = 256

const SENSOR_MAPPING = {
  InternalTemperatureSensor: 0,
  _DEPRECATED_Temperature_Ambient: 1,
  DevelopmentTestSensor: 2,
  MCU: 3,
  _DEPRECATED_Internal3: 4,
  // 5-7 Reserved
  ExternalPort1: 8,
  ExternalPort2: 9,
  ExternalPort3: 10,
  // 11-15 Reserved
  InternalMicrophone: 16,
  // 17-31 Reserved
  InternalAccelerometer: 32,
  _DEPRECATED_Accelerometer_ExternalPort1: 33,
  _DEPRECATED_Accelerometer_ExternalPort2: 34,
  _DEPRECATED_Accelerometer_ExternalPort3: 35,
  // 36-47 Reserved
  InternalBatteryMeter: 48,
  _DEPRECATED_MCU_Voltage: 49,
  // 50-63 Reserved
  InternalPressureSensor: 64,
  _DEPRECATED_Pressure_ExternalPort1: 65,
  _DEPRECATED_Pressure_ExternalPort2: 66,
  _DEPRECATED_Pressure_ExternalPort3: 67,
  InternalHumiditySensor: 68,
  // 69-79 Reserved
  System_Information: 80,
  System_Disk: 81,
  System_RAM: 82,
  System_Ethernet: 83,
  System_WiFi: 84,
  System_Bluetooth: 85,
  System_Mobile: 86,
  // 86-95 Reserved
  Aggregation_EventCounter: 96,
  // 98-111 Reserved
  Unknown: 255,
}

const AXIS_TYPES = {
  0: null,
  1: "X",
  2: "Y",
  3: "XY",
  4: "Z",
  5: "XZ",
  6: "YZ",
  7: "XYZ",
  8: "Omnidirectional",
  9: "XO",
  10: "YO",
  11: "XYO",
  12: "ZO",
  13: "XZO",
  14: "YZO",
  15: "XYZO",
}

const AXIS_MAPPING = {
  NA: 0,
  X: 1,
  Y: 2,
  XY: 3,
  Z: 4,
  XZ: 5,
  YZ: 6,
  XYZ: 7,
  Omnidirectional: 8,
  XO: 9,
  YO: 10,
  XYO: 11,
  ZO: 12,
  XZO: 13,
  YZO: 14,
  XYZO: 15,
}

const CONDITION_MAPPING = {
  Equal: 0,
  LowerThan: -1,
  GreaterThan: 1,
}

const ALERT_INFO = 0
const ALERT_WARNING = 1
const ALERT_ERROR = 2

const ALERT_TYPES = {
  [ALERT_INFO]: {
    cssClass: "blue darken-3",
    icon: "info.png",
  },
  [ALERT_WARNING]: {
    cssClass: "amber black-text",
    icon: "warn.png",
  },
  [ALERT_ERROR]: {
    cssClass: "red darken-4",
    icon: "error.png",
  },
}

const SEVERITY_MAPPING = {
  Warning: 0,
  Alert: 1,
  Critical: 2,
  UnitMessage: 3,
  FOTANotification: 4,
  FOTABootloader: 5,
  LowPressure: 6,
  LowBattery: 7,
  BIST: 8,
  DataGenerator: 9,
}

const TIME_SPAN_MAPPING = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  AllTime: 4,
}

const UNIT_TYPE_MAPPING = {
  MCL: 1,
  mMCL: 2,
  Operator: 3,
  USN: 4,
  OGT: 5,
  ISN: 6,
  USNB: 7,
  ISNB: 8,
  ISNBS: 9,
  GW: 10,
  ISNLite: 52,
}

const UNIT_TYPES = {
  1: { id: 1, name: "MCL" },
  2: { id: 2, name: "mMCL" },
  3: { id: 3, name: "Operator" },
  4: { id: 4, name: "USN" },
  5: { id: 5, name: "OGT" },
  6: { id: 6, name: "ISN" },
  7: { id: 7, name: "USNB" },
  8: { id: 8, name: "ISNB" },
  9: { id: 9, name: "ISNBS" },
  10: { id: 10, name: "GW" },
  52: { id: 52, name: "ISNLite" },
}

const GW_HARDWARE_VERSION_TYPES = {
  0: { id: 0, name: "NoVersion" },
  1: { id: 1001, name: "Version_1" },
  2: { id: 1002, name: "Version_2" },
  3: { id: 1003, name: "Version_3" },
  4: { id: 1004, name: "Version_4" },
}

const ISN_HARDWARE_VERSION_TYPES = {
  0: { id: 0, name: "NoVersion" },
  1: { id: 1, name: "Mk1" },
  2: { id: 2, name: "Mk1B" },
  3: { id: 3, name: "Mk1C" },
  4: { id: 4, name: "Mk3" },
  5: { id: 5, name: "Mk3B" },
  6: { id: 6, name: "Mk3C" },
  7: { id: 7, name: "Mk3D" },
  9: { id: 9, name: "Mk3D_BP" },
  10: { id: 10, name: "Mk3D_12V" },
  11: { id: 11, name: "Mk3D_NC" },
  12: { id: 100, name: "Mk3D_F" },
  13: { id: 101, name: "Mk1B_ByPass" },
  14: { id: 200, name: "CEB_12V_1_0" },
  15: { id: 201, name: "CEB_12V_1_3" },
}

const ISN_LITE_HARDWARE_VERSION_TYPES = {
  0: { id: 5207, name: "R1_0_A" },
  1: { id: 5208, name: "R1_1_A" },
  2: { id: 5209, name: "R1_2_A" },
  3: { id: 5210, name: "R1_2_B" },
  4: { id: 5211, name: "R1_2_C" },
}

const UNIFIED_HARDWARE_VERSION_TYPES = {
  0: { id: 0, name: "NoVersion" },
  1: { id: 1, name: "Mk1" },
  2: { id: 2, name: "Mk1B" },
  3: { id: 3, name: "Mk1C" },
  4: { id: 4, name: "Mk3" },
  5: { id: 5, name: "Mk3B" },
  6: { id: 6, name: "Mk3C" },
  7: { id: 7, name: "Mk3D" },
  9: { id: 9, name: "Mk3D_BP" },
  10: { id: 10, name: "Mk3D_12V" },
  11: { id: 11, name: "Mk3D_NC" },
  100: { id: 100, name: "Mk3D_F" },
  101: { id: 101, name: "Mk1B_ByPass" },
  200: { id: 200, name: "CEB_12V_1_0" },
  201: { id: 201, name: "CEB_12V_1_3" },
  1001: { id: 1001, name: "Version_1" },
  1002: { id: 1002, name: "Version_2" },
  1003: { id: 1003, name: "Version_3" },
  1004: { id: 1004, name: "Version_4" },
  5207: { id: 5207, name: "R1_0_A" },
  5208: { id: 5208, name: "R1_1_A" },
  5209: { id: 5209, name: "R1_2_A" },
  5210: { id: 5210, name: "R1_2_B" },
  5211: { id: 5211, name: "R1_2_C" },
}

const MEASUREMENT_KEYS = {
  0: { id: "CurrentValue", name: "Current Value" },
  1: { id: "Average", name: "Average" },
  2: { id: "Maximum", name: "Maximum" },
  3: { id: "Minimum", name: "Minimum" },
  4: { id: "RMS", name: "RMS" },
  5: { id: "Count", name: "Count" },
  6: { id: "PeakToPeak", name: "Peak to Peak" },
}

const MEASUREMENT_SYSTEMS = {
  International: { id: 0, name: "International System of Units" },
  Imperial: { id: 1, name: "Imperial and US customary" },
}

const NOTIFICATION_ITEM_TYPE = {
  Threshold: {
    ThresholdId: { tag: "@ThresholdId" },
    ThresholdDescription: { tag: "@ThresholdDescription" },
    ThresholdCondition: { tag: "@ThresholdCondition" },
    ThresholdDataType: { tag: "@ThresholdDataType" },
    ThresholdSensorType: { tag: "@ThresholdSensorType" },
    ThresholdSeverity: { tag: "@ThresholdSeverity" },
    ThresholdValue: { tag: "@ThresholdValue" },
  },
  Unit: {
    UnitId: { tag: "@UnitId" },
    UnitDescription: { tag: "@UnitDescription" },
    UnitApplication: { tag: "@UnitApplication" },
    UnitBootloader: { tag: "@UnitBootloader" },
  },
  Asset: {
    AssetId: { tag: "@AssetId" },
    AssetDescription: { tag: "@AssetDescription" },
  },
  Company: {
    CompanyId: { tag: "@CompanyId" },
    CompanyDescription: { tag: "@CompanyDescription" },
  },
  Others: {
    CurrentDate: { tag: "@CurrentDate" },
    CurrentValue: { tag: "@CurrentValue" },
    DataGenerationDate: { tag: "@DataGenerationDate" },
  },
}

const BATTERY_LEVELS = {
  0: { icon: "fa fa-battery-full", usage: 0 },
  1: { icon: "fa fa-battery-three-quarters", usage: 13 },
  2: { icon: "fa fa-battery-half", usage: 38 },
  3: { icon: "fa fa-battery-quarter", usage: 63 },
  4: { icon: "fa fa-battery-empty", usage: 88 },
}

/*eslint-disable */
const ROLE_ENTITY = {
  Asset: { canDelete: false, canSave: false, canRead: false, i18n: "asset" },
  Company: { canDelete: false, canSave: false, canRead: false, i18n: "company" },
  Group: { canDelete: false, canSave: false, canRead: false, i18n: "group" },
  Notification: { canDelete: false, canSave: false, canRead: false, i18n: "notification" },
  Role: { canDelete: false, canSave: false, canRead: false, i18n: "role" },
  ThresholdBase: { canDelete: false, canSave: false, canRead: false, i18n: "threshold" },
  Unit: { canDelete: false, canSave: false, canRead: false, i18n: "unit" },
  User: { canDelete: false, canSave: false, canRead: false, i18n: "user" },
}

const ROLE_FEATURE = {
  DashboardAccess: { id: 1, canAccess: false, i18nDescription: "feature_dashboard" },
  DataAnalyticsAccess: { id: 2, canAccess: false, i18nDescription: "feature_analytics" },
  ManualsAccess: { id: 3, canAccess: false, i18nDescription: "feature_manuals" },
  FotaAccess: { id: 4, canAccess: false, i18nDescription: "feature_fota" },
  FotaReportAccess: { id: 5, canAccess: false, i18nDescription: "feature_fota_report" },
  FotaFilesDownload: { id: 6, canAccess: false, i18nDescription: "feature_fota_files" },
  DataUpload: { id: 7, canAccess: false, i18nDescription: "feature_data_upload" },
  ModifyUnitAdministrationSettings: {
    id: 8,
    canAccess: false,
    i18nDescription: "feature_unit_administration",
  },
  ModifyUnitChargingSettings: {
    id: 9,
    canAccess: false,
    i18nDescription: "feature_unit_charging_settings",
  },
  ModifyUnitInstallationSettings: {
    id: 10,
    canAccess: false,
    i18nDescription: "feature_unit_installation_settings",
  },
}

const ROLE_TYPES = {
  SystemDefinedAdmin: {
    type: "SystemDefinedAdmin",
    i18nType: "system_defined_admin",
    isEditable: false,
  },
  SystemDefinedNonAdmin: {
    type: "SystemDefinedNonAdmin",
    i18nType: "system_defined_non_admin",
    isEditable: true,
  },
  SystemDefinedReadOnly: {
    type: "SystemDefinedReadOnly",
    i18nType: "system_defined_read_only",
    isEditable: false,
  },
  SystemDefinedDevice: {
    type: "SystemDefinedDevice",
    i18nType: "system_defined_device",
    isEditable: false,
  },
  UserDefined: { type: "UserDefined", i18nType: "user_defined", isEditable: true },
  SystemDefinedSuperAdmin: {
    type: "SystemDefinedSuperAdmin",
    i18nType: "system_defined_super_admin",
    isEditable: false,
  },
  SystemDefinedProduction: {
    type: "SystemDefinedProduction",
    i18nType: "system_defined_production",
    isEditable: false,
  },
}

const USER_TYPES = {
  NotDefined: 0,
  CombiQloud: 1,
  Production: 2,
}

const BIST_RESULT_TYPES = {
  NotApplicable: { value: 0, i18nLabel: "bist_result_types.not_applicable" },
  Pass: { value: 1, i18nLabel: "bist_result_types.passed" },
  Failed: { value: 2, i18nLabel: "bist_result_types.failed" },
  NotTested: { value: 3, i18nLabel: "bist_result_types.not_tested" },
}

const ACCESSTECHNOLOGY_TYPES = {
  NotApplicable: { value: 0, i18nLabel: "accesstechnology_types.not_applicable" },
  B2G: { value: 1, i18nLabel: "accesstechnology_types.b2g" },
  B3G: { value: 2, i18nLabel: "accesstechnology_types.b3g" },
  B4G: { value: 3, i18nLabel: "accesstechnology_types.b4g" },
}

const STATUS_DATA_GENERATOR = {
  NotApplicable: { value: 0, i18nLabel: "status_data_generator.not_applicable" },
  DUGM: { value: 1, i18nLabel: "status_data_generator.sync" },
  InternalError: { value: 2, i18nLabel: "status_data_generator.internal_error" },
  CommunicationFailure: { value: 3, i18nLabel: "status_data_generator.communication_failure" },
  NotConnected: { value: 4, i18nLabel: "status_data_generator.not_connected" },
  UnknownSensorConnected: { value: 5, i18nLabel: "status_data_generator.unknown_sensor_connected" },
  SanityCheckFailure: { value: 6, i18nLabel: "status_data_generator.sanity_check_failure" },
  ProcessingFailure: { value: 7, i18nLabel: "status_data_generator.processing_failure" },
  Connected: { value: 8, i18nLabel: "status_data_generator.connected" },
}

const COMPANY_PROFILES = {
  CombiQ: { id: 1, i18nLabel: "profile_combiq_label" },
  Stromsholmen: { id: 2, i18nLabel: "profile_stromsholmen_label" },
}

const DATA_TYPES_DATA_GENERATORS = {
  [DT_NOT_APPLICABLE]: [256],
  [DT_UN_ACCELERATION]: [8, 9, 10, 32],
  [DT_UN_ACCELERATION_FREQUENCY_SPECTRUM]: [8, 9, 10, 32],
  [DT_UN_COMPENSATED_PRESSURE]: [8, 9, 10],
  [DT_UN_DISPLACEMENT]: [8, 9, 10, 32],
  [DT_UN_DISPLACEMENT_FREQUENCY_SPECTRUM]: [8, 9, 10],
  [DT_UN_PRESSURE]: [8, 9, 10, 64],
  [DT_UN_RAW_RPM]: [8, 9, 10],
  [DT_UN_RESISTANCE]: [8, 9, 10, 48],
  [DT_UN_SOUND]: [8, 9, 10, 16],
  [DT_UN_SOUND_FREQUENCY_SPECTRUM]: [8, 9, 10, 16],
  [DT_UN_ACTIVE_CONNECTIONS]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_DISCOVERED_DEVICES]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_FAILED_COMMUNICATIONS]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_FAILED_POSTS]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_REBOOT]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_RSSI]: [8, 9, 10, 85],
  [DT_UN_UPLOAD_DOWNLOAD_BYTES]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_UPLOAD_DOWNLOAD_DROPPED]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_UPLOAD_DOWNLOAD_ERRORS]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_UPLOAD_SPEED]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_UPTIME]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_USAGE]: [8, 9, 10, 80, 81, 82, 83, 84, 85, 86],
  [DT_UN_TEMPERATURE]: [0, 3, 8, 9, 10, 32, 64],
  [DT_UN_TEST_DATA]: [8, 9, 10],
  [DT_UN_VELOCITY]: [8, 9, 10, 32],
  [DT_UN_VELOCITY_FREQUENCY_SPECTRUM]: [8, 9, 10, 32],
  [DT_UN_VOLTAGE]: [8, 9, 10, 48],
  [DT_UN_STROKES]: [8, 9, 10, 32],
  [DT_UN_DERIVATIVE_ACCELERATION]: [8, 9, 10],
  [DT_ELECTRIC_CURRENT]: [8, 9, 10],
  [DT_RELATIVE_HUMIDITY]: [8, 9, 10, 68],
  [DT_ELECTRIC_POWER]: [8, 9, 10],
  [DT_SWITCH]: [8, 9, 10],
  [DT_UN_ENERGY]: [8, 9, 10],
}

const AXIS_DESCRIPTION = {
  NA: { value: 0, axis: [], multiPoint: [] },
  X: { value: 1, axis: ["X"], multiPoint: ["xAxisData"] },
  Y: { value: 2, axis: ["Y"], multiPoint: ["yAxisData"] },
  Z: { value: 4, axis: ["Z"], multiPoint: ["zAxisData"] },
  XY: { value: 3, axis: ["X", "Y"], multiPoint: ["xAxisData", "yAxisData"] },
  XZ: { value: 5, axis: ["X", "Z"], multiPoint: ["xAxisData", "zAxisData"] },
  YZ: { value: 6, axis: ["Y", "Z"], multiPoint: ["yAxisData", "zAxisData"] },
  XYZ: { value: 7, axis: ["X", "Y", "Z"], multiPoint: ["xAxisData", "yAxisData", "zAxisData"] },
  Omnidirectional: { value: 8, axis: ["Omnidirectional"], multiPoint: ["omnidirectionalAxisData"] },
}

const COMPANY_FEATURES = [
  {
    type: 1,
        section: "isn-dashboard",
        i18nLabel: "isn_cards_on_dashboard",
    features: [
      { id: 4, name: "temperatureCard", i18nLabel: "company_features.temperature_card" },
      { id: 5, name: "vibrationCard", i18nLabel: "company_features.vibration_card" },
      { id: 6, name: "runningTimeCard", i18nLabel: "company_features.running_time_card" },
      { id: 7, name: "alertCountCard", i18nLabel: "company_features.alert_count_card" },
      { id: 8, name: "productionCard", i18nLabel: "company_features.production_card" },
      { id: 9, name: "pressureCard", i18nLabel: "company_features.pressure_card" },
      { id: 10, name: "maintenanceCard", i18nLabel: "company_features.maintenance_card" },
      { id: 11, name: "humidityCard", i18nLabel: "company_features.humidity_card" },
      { id: 12, name: "powerConsumptionCard", i18nLabel: "company_features.power_consumption_card" },
    ],
  },
  {
    type: 1,
    section: "bs-dashboard",
    i18nLabel: "bs_cards_on_dashboard",
    features: [
      { id: 4, name: "bs_temperatureCard", i18nLabel: "company_features.temperature_card" },
      { id: 5, name: "bs_vibrationCard", i18nLabel: "company_features.vibration_card" },
      { id: 6, name: "bs_runningTimeCard", i18nLabel: "company_features.running_time_card" },
      { id: 7, name: "bs_alertCountCard", i18nLabel: "company_features.alert_count_card" },
      { id: 8, name: "bs_productionCard", i18nLabel: "company_features.production_card" },
      { id: 9, name: "bs_pressureCard", i18nLabel: "company_features.pressure_card" },
      { id: 10, name: "bs_maintenanceCard", i18nLabel: "company_features.maintenance_card" },
      { id: 11, name: "bs_humidityCard", i18nLabel: "company_features.humidity_card" },
      { id: 12, name: "bs_powerConsumptionCard", i18nLabel: "company_features.power_consumption_card" },
    ],
  },
  {
    type: 2,
    section: "data-analytics",
    i18nLabel: "charts_on_data_analytics",
    features: [
      {
        id: 13,
        name: "da_productionTimeChart",
        i18nLabel: "company_features.productionTime_chart",
        tabId: "ptChart",
      },
      {
        id: 14,
        name: "da_productionReadingsChart",
        i18nLabel: "company_features.productionReadings_chart",
        tabId: "pRead",
      },
      {
        id: 15,
        name: "da_strokesPerMinuteChart",
        i18nLabel: "company_features.strokesPerMinute_chart",
        tabId: "spm",
      },
      {
        id: 16,
        name: "da_overviewTable",
        i18nLabel: "company_features.overview_table",
        tabId: "oview",
      },
      {
        id: 17,
        name: "da_sensorReadingsChart",
        i18nLabel: "company_features.sensorReadings_chart",
        tabId: "sRead",
        subFeatures: [],
      },
      {
        id: 18,
        name: "da_showTrendLinesToggleButton",
        i18nLabel: "company_features.showTrendLines_toggleButton",
      },
      {
        id: 19,
        name: "da_showEnergyConsumption",
        i18nLabel: "company_features.showEnergyConsumption_chart",
        tabId: "nrgChart",
      },
    ],
  },
]

const AGGREGATION_INSTANCE_TYPE = {
  Restart: { id: 8 },
  SystemInformation: { id: 10 },
  Debug: { id: 11 },
  SinglePoint: { id: 12 },
  MultiPoint: { id: 13 },
  FOTANotification: { id: 15 },
  Event: { id: 16 },
}

const AGGREGATION_TIMESPAN_TYPE = {
  Minute: { id: 0, isAvailableInSinglepoint: false },
  Hour: { id: 1, isAvailableInSinglepoint: true },
  Day: { id: 2, isAvailableInSinglepoint: false },
  Week: { id: 3, isAvailableInSinglepoint: false },
  Month: { id: 4, isAvailableInSinglepoint: false },
  AllTime: { id: 5, isAvailableInSinglepoint: false },
  HalfHour: { id: 6, isAvailableInSinglepoint: false },
}

const AGGREGATION_FUNCTIONS = {
  Average: { id: 0, name: "Average", i18nLabel: "average", aggregateField: "average" },
  Minimum: { id: 1, name: "Minimum", i18nLabel: "minimum", aggregateField: "minimum" },
  Maximum: { id: 2, name: "Maximum", i18nLabel: "maximum", aggregateField: "maximum" },
}

const EXPORT_TIMERANGE_TYPES = {
  Last10Minutes: { id: 0, i18nLabel: "form.input.timeRangeOption.10min" },
  LastHour: { id: 1, i18nLabel: "form.input.timeRangeOption.hour" },
  Last24Hours: { id: 2, i18nLabel: "form.input.timeRangeOption.day" },
  LastWeek: { id: 3, i18nLabel: "form.input.timeRangeOption.week" },
  LastMonth: { id: 4, i18nLabel: "form.input.timeRangeOption.month" },
  Last30Days: { id: 5, i18nLabel: "form.input.timeRangeOption.30days" },
  CurrentWeek: { id: 6, i18nLabel: "form.input.timeRangeOption.current_week" },
  CurrentMonth: { id: 7, i18nLabel: "form.input.timeRangeOption.current_month" },
}

const EXPORT_TIMEMARGIN_TYPES = {
  Custom: { id: 0, i18nLabel: "export_timemargin_types.custom" },
  Hourly: { id: 60, i18nLabel: "export_timemargin_types.hourly" },
  Daily: { id: 1440, i18nLabel: "export_timemargin_types.daily" },
  Weekly: { id: 10080, i18nLabel: "export_timemargin_types.weekly" },
  Monthly: { id: 43800, i18nLabel: "export_timemargin_types.monthly" },
}

const THRESHOLD_EXPIRATION_TYPES = {
  NoExpiration: { id: 0, i18nLabel: "threshold_expiration_types.noExpiration" },
  Hour: { id: 60, i18nLabel: "threshold_expiration_types.hour" },
  Day: { id: 1440, i18nLabel: "threshold_expiration_types.day" },
  Week: { id: 10080, i18nLabel: "threshold_expiration_types.week" },
  ThirtyDays: { id: 43200, i18nLabel: "threshold_expiration_types.thirtyDays" },
}

const THRESHOLD_TIMEPERIOD_TYPES = {
  Anytime: { id: 0, i18nLabel: "threshold_timeperiod_types.anytime" },
  Minute: { id: 1, i18nLabel: "threshold_timeperiod_types.minute" },
  FiveMinutes: { id: 5, i18nLabel: "threshold_timeperiod_types.fiveMinutes" },
  FifteenMinutes: { id: 15, i18nLabel: "threshold_timeperiod_types.fifteenMinutes" },
  ThirtyMinutes: { id: 30, i18nLabel: "threshold_timeperiod_types.thirtyMinutes" },
  Hour: { id: 60, i18nLabel: "threshold_timeperiod_types.hour" },
  SixHours: { id: 360, i18nLabel: "threshold_timeperiod_types.sixHours" },
  TwelveHours: { id: 720, i18nLabel: "threshold_timeperiod_types.twelveHours" },
  Day: { id: 1440, i18nLabel: "threshold_timeperiod_types.day" },
  Week: { id: 10080, i18nLabel: "threshold_timeperiod_types.week" },
}

const EXPORT_RUNATS = {
  0: { id: 0, i18nLabel: "export_runat_types.hour0" },
  1: { id: 1, i18nLabel: "export_runat_types.hour1" },
  2: { id: 2, i18nLabel: "export_runat_types.hour2" },
  3: { id: 3, i18nLabel: "export_runat_types.hour3" },
  4: { id: 4, i18nLabel: "export_runat_types.hour4" },
  5: { id: 5, i18nLabel: "export_runat_types.hour5" },
  6: { id: 6, i18nLabel: "export_runat_types.hour6" },
  7: { id: 7, i18nLabel: "export_runat_types.hour7" },
  8: { id: 8, i18nLabel: "export_runat_types.hour8" },
  9: { id: 9, i18nLabel: "export_runat_types.hour9" },
  10: { id: 10, i18nLabel: "export_runat_types.hour10" },
  11: { id: 11, i18nLabel: "export_runat_types.hour11" },
  12: { id: 12, i18nLabel: "export_runat_types.hour12" },
  13: { id: 13, i18nLabel: "export_runat_types.hour13" },
  14: { id: 14, i18nLabel: "export_runat_types.hour14" },
  15: { id: 15, i18nLabel: "export_runat_types.hour15" },
  16: { id: 16, i18nLabel: "export_runat_types.hour16" },
  17: { id: 17, i18nLabel: "export_runat_types.hour17" },
  18: { id: 18, i18nLabel: "export_runat_types.hour18" },
  19: { id: 19, i18nLabel: "export_runat_types.hour19" },
  20: { id: 20, i18nLabel: "export_runat_types.hour20" },
  21: { id: 21, i18nLabel: "export_runat_types.hour21" },
  22: { id: 22, i18nLabel: "export_runat_types.hour22" },
  23: { id: 23, i18nLabel: "export_runat_types.hour23" },
}

const AGGREGATION_TYPE = {
  Downsampling: { id: 1 },
  Aggregation: { id: 2 },
}

const CLAMP_TYPE = {
    A10: { id: "A10", name: "10 A" },
    A20: { id: "A20", name: "20 A" },
    A30: { id: "A30", name: "30 A" },
    A50: { id: "A50", name: "50 A" },
    A60: { id: "A60", name: "60 A" },
    A100: { id: "A100", name: "100 A" },
    A200: { id: "A200", name: "200 A" },
    A400: { id: "A400", name: "400 A" },
}
const PHASE_TYPE = {
    OnePhase: { id: "OnePhase", name: "1 Φ" },
    ThreePhase: { id: "ThreePhase", name: "3 Φ" },
}
const VOLTAGE_TYPE = {
    V230: { id: "V230", name: "230 V" },
    V400: { id: "V400", name: "400 V" },
}

const COMPANY_PROFILE_TYPE = {
    Undefined: { id: 0 },
    Collision: { id: 1 },
    Industrial: { id: 2 },
    Ventilation: { id: 3 },
    PlasticGrinding: { id: 4 },
    Other: { id: 255 },
}
/* eslint-enable */

export default {
  DATA_TYPES,
  DATA_MAPPING,
  ALERT_TYPES,
  SENSOR_TYPES,
  SENSOR_MAPPING,
  CONDITION_MAPPING,
  SEVERITY_MAPPING,
  TIME_SPAN_MAPPING,
  AXIS_TYPES,
  AXIS_MAPPING,
  UNIT_TYPES,
  UNIT_TYPE_MAPPING,
  ISN_HARDWARE_VERSION_TYPES,
  ISN_LITE_HARDWARE_VERSION_TYPES,
  GW_HARDWARE_VERSION_TYPES,
  UNIFIED_HARDWARE_VERSION_TYPES,
  MEASUREMENT_KEYS,
  MEASUREMENT_SYSTEMS,
  NOTIFICATION_ITEM_TYPE,
  THRESHOLD_TYPES,
  SINGLE_POINT_DATA_TYPES,
  MULTI_POINT_DATA_TYPES,
  DT_NOT_APPLICABLE,
  NOT_APPLICABLE_SENSOR_TYPE,
  BATTERY_LEVELS,
  ROLE_ENTITY,
  ROLE_FEATURE,
  ROLE_TYPES,
  USER_TYPES,
  BIST_RESULT_TYPES,
  ACCESSTECHNOLOGY_TYPES,
  STATUS_DATA_GENERATOR,
  COMPANY_PROFILES,
  RUNNING_TIME_DATA_TYPES,
  CHARGING_SETTINGS_DATA_TYPES,
  LOW_BATTERY_DATA_TYPES,
  LOST_CONNECTION_DATA_TYPES,
  DATA_TYPES_DATA_GENERATORS,
  COMPANY_FEATURES,
  MS_INTERNATIONAL,
  MS_IMPERIAL,
  DT_ELECTRIC_CURRENT,
  DT_RELATIVE_HUMIDITY,
  DT_ELECTRIC_POWER,
  DT_SWITCH,
  DT_UN_ENERGY,
  DT_UN_ACCELERATION,
  DT_UN_ACCELERATION_FREQUENCY_SPECTRUM,
  DT_UN_COMPENSATED_PRESSURE,
  DT_UN_DISPLACEMENT,
  DT_UN_DISPLACEMENT_FREQUENCY_SPECTRUM,
  DT_UN_PRESSURE,
  DT_UN_RAW_RPM,
  DT_UN_RESISTANCE,
  DT_UN_SOUND,
  DT_UN_SOUND_FREQUENCY_SPECTRUM,
  DT_UN_ACTIVE_CONNECTIONS,
  DT_UN_DISCOVERED_DEVICES,
  DT_UN_FAILED_COMMUNICATIONS,
  DT_UN_FAILED_POSTS,
  DT_UN_REBOOT,
  DT_UN_RSSI,
  DT_UN_UPLOAD_DOWNLOAD_BYTES,
  DT_UN_UPLOAD_DOWNLOAD_DROPPED,
  DT_UN_UPLOAD_DOWNLOAD_ERRORS,
  DT_UN_UPLOAD_SPEED,
  DT_UN_UPTIME,
  DT_UN_USAGE,
  DT_UN_TEMPERATURE,
  DT_UN_TEST_DATA,
  DT_UN_VELOCITY,
  DT_UN_VELOCITY_FREQUENCY_SPECTRUM,
  DT_UN_VOLTAGE,
  DT_UN_STROKES,
  DT_UN_DERIVATIVE_ACCELERATION,
  DT_HOURS,
  DT_MINUTES,
  MS_VALUE_UOM,
  MS_VALUE,
  MS_UOM,
  MS_MAPPING,
  MS_ORIGINAL_VALUE_UOM,
  DT_RT_G,
  DT_PERCENTAGE,
  AXIS_DESCRIPTION,
  AGGREGATION_INSTANCE_TYPE,
  AGGREGATION_TIMESPAN_TYPE,
  AGGREGATION_FUNCTIONS,
  EXPORT_TIMERANGE_TYPES,
  EXPORT_TIMEMARGIN_TYPES,
  EXPORT_RUNATS,
  THRESHOLD_EXPIRATION_TYPES,
  THRESHOLD_TIMEPERIOD_TYPES,
  AGGREGATION_TYPE,
  CLAMP_TYPE,
  PHASE_TYPE,
  VOLTAGE_TYPE,
  COMPANY_PROFILE_TYPE,
}
