import i18n from "../i18n"
import moment from "moment-timezone"
import Utc from "../tz-time-zones"
import spec from "../spec"

export default {
  install(Vue, store) {
    // Unit details functions
    Vue.prototype.$isIsnType = (type) => {
      return ["ISN", "ISNLite", "ISNB"].includes(type)
    }

    Vue.prototype.$isBsType = (type) => {
      return type === "ISNBS"
    }

    Vue.prototype.$getUnitDetails = (unit, isSourceRealtime) => {
      Vue.set(unit, "$ID", unit.entityKey)
      Vue.set(unit, "activationDate", Vue.prototype.$getDateWithOffset(unit.activationDate))
      Vue.set(unit, "lastBISTDate", Vue.prototype.$getDateWithOffset(unit.lastBISTDate))
      Vue.set(unit, "lastConnectionOn", Vue.prototype.$getDateWithOffset(unit.lastConnectionOn))
      Vue.set(unit, "lastDUGMOn", Vue.prototype.$getDateWithOffset(unit.lastDUGMOn))
      Vue.set(unit, "lastNearbyOn", Vue.prototype.$getDateWithOffset(unit.lastNearbyOn))
      Vue.set(unit, "batteryIcon", Vue.prototype.$getBatteryIcon(unit))
      Vue.set(unit, "batteryHoverMsg", Vue.prototype.$getHoverMessage(unit, "battery"))
      Vue.set(unit, "indicatorColor", Vue.prototype.$getUnitStatus(unit))
      Vue.set(unit, "indicatorHoverMsg", Vue.prototype.$getHoverMessage(unit, "status"))
      Vue.set(unit, "signalIcon", Vue.prototype.$getSignalIcon(unit))
      Vue.set(unit, "signalHoverMsg", Vue.prototype.$getHoverMessage(unit, "signal"))

      if (isSourceRealtime) {
        Vue.set(unit, "type", Vue.prototype.$getUnitTypes(unit.type))
        Vue.set(unit, "hardwareVersion", spec.UNIFIED_HARDWARE_VERSION_TYPES[unit.hardwareVersion].name)
      }

      return unit
    }

    Vue.prototype.$getUnitTypes = (value) => {
      if (isNaN(value)) return null

      const index = Object.keys(spec.UNIT_TYPES).find((x) => spec.UNIT_TYPES[x].id === value)

      return spec.UNIT_TYPES[index].name
    }

    Vue.prototype.$getBatteryIcon = (row) => {
      let icon = ""
      const keys = Object.keys(spec.BATTERY_LEVELS)

      keys.forEach(function (i) {
        if (row.batteryUsage !== null && spec.BATTERY_LEVELS[i].usage <= row.batteryUsage) icon = spec.BATTERY_LEVELS[i].icon
      })

      return icon
    }

    Vue.prototype.$getHoverMessage = (row, indicator) => {
      const dataGeneratorValue = !isNaN(row.lastDataGeneratorStatus)
        ? row.lastDataGeneratorStatus
        : spec.STATUS_DATA_GENERATOR[row.lastDataGeneratorStatus].value
      const lastBISTResult = !isNaN(row.lastBISTResult) ? row.lastBISTResult : spec.BIST_RESULT_TYPES[row.lastBISTResult].value

      let status = ""
      let signal = `${i18n.t("unitFields.no_connectivity_label")}`
      switch (indicator) {
        case "status":
          // Connectivity
          if (Vue.prototype.$hasLessMinutesThan(20, row.lastDUGMOn)) {
            status = `${i18n.t("unitFields.connectivity_ok")}`
          } else {
            status =
              `${i18n.t("unitFields.sync_20_minutes")}` +
              `\n${i18n.t("unitFields.lastSync")}: ${Vue.prototype.$formatEmptyText(row.lastDUGMOn)}`
          }

          // Hardware
          if (
            (dataGeneratorValue === spec.STATUS_DATA_GENERATOR.NotApplicable.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.DUGM.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.Connected.value) &&
            lastBISTResult !== spec.BIST_RESULT_TYPES.Failed.value
          ) {
            status += `\n${i18n.t("unitFields.hardware_ok")}`
          } else {
            if (lastBISTResult === spec.BIST_RESULT_TYPES.Failed.value) {
              status += `\n${i18n.t("unitFields.bist_label")}: ${i18n.t(spec.BIST_RESULT_TYPES.Failed.i18nLabel)}`
            }

            if (
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.InternalError.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.CommunicationFailure.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.NotConnected.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.UnknownSensorConnected.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.SanityCheckFailure.value ||
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.ProcessingFailure.value
            ) {
              status += `\n${i18n.t("status")}: ${i18n.t(
                spec.SENSOR_TYPES[spec.SENSOR_MAPPING[row.lastDataGenerator]].name
              )} - ${i18n.t(spec.STATUS_DATA_GENERATOR[row.lastDataGeneratorStatus].i18nLabel)}`
            }
          }

          return status

        case "battery":
          return row.batteryUsage === null ? "" : `${i18n.t("unitFields.battery")}: ${100 - row.batteryUsage}%`

        case "signal":
          if (row.lastConnectivityInformation !== null) {
            if (
              dataGeneratorValue === spec.STATUS_DATA_GENERATOR.NotConnected.value &&
              spec.SENSOR_MAPPING[row.lastConnectivityInformation.sensorId] === spec.SENSOR_MAPPING.System_Mobile
            ) {
              signal = `${i18n.t("unitFields.no_mobile_connectivity")}`
            } else {
              const technology = isNaN(row.lastConnectivityInformation.accessTechnology)
                ? row.lastConnectivityInformation.accessTechnology
                : Object.keys(spec.ACCESSTECHNOLOGY_TYPES).find(
                    (x) => spec.ACCESSTECHNOLOGY_TYPES[x].value === row.lastConnectivityInformation.accessTechnology
                  )

              signal = `${row.lastConnectivityInformation.host} - ${i18n.t(spec.ACCESSTECHNOLOGY_TYPES[technology].i18nLabel)}`
            }
          }
          return signal
      }
    }

    Vue.prototype.$getSignalIcon = (row) => {
      const icon = ""

      if (row.lastConnectivityInformation !== null) {
        const statusId = !isNaN(row.lastConnectivityInformation.statusId)
          ? row.lastConnectivityInformation.statusId
          : spec.STATUS_DATA_GENERATOR[row.lastConnectivityInformation.statusId].value

        if (
          statusId === spec.STATUS_DATA_GENERATOR.NotConnected.value &&
          spec.SENSOR_MAPPING[row.lastConnectivityInformation.sensorId] === spec.SENSOR_MAPPING.System_Mobile
        )
          return "mdi mdi-signal-cellular-outline"
        if (row.lastConnectivityInformation.rssi <= -100) return "mdi mdi-signal-cellular-1"
        if (row.lastConnectivityInformation.rssi > -100 && row.lastConnectivityInformation.rssi <= -90)
          return "mdi mdi-signal-cellular-2"
        if (row.lastConnectivityInformation.rssi > -90) return "mdi mdi-signal-cellular-3"
      }
      return icon
    }

    Vue.prototype.$getUnitStatus = (row) => {
      const dataGeneratorValue = !isNaN(row.lastDataGeneratorStatus)
        ? row.lastDataGeneratorStatus
        : spec.STATUS_DATA_GENERATOR[row.lastDataGeneratorStatus].value
      const lastBISTResult = !isNaN(row.lastBISTResult) ? row.lastBISTResult : spec.BIST_RESULT_TYPES[row.lastBISTResult].value

      if (
        (dataGeneratorValue === spec.STATUS_DATA_GENERATOR.NotApplicable.value ||
          dataGeneratorValue === spec.STATUS_DATA_GENERATOR.DUGM.value ||
          dataGeneratorValue === spec.STATUS_DATA_GENERATOR.Connected.value) &&
        lastBISTResult !== spec.BIST_RESULT_TYPES.Failed.value &&
        Vue.prototype.$hasLessMinutesThan(20, row.lastDUGMOn)
      )
        return "green"
      else return "red"
    }

    // Insights functions
    Vue.prototype.$getInsightDetails = (item) => {
      Vue.set(item, "$ID", item.entityKey)
      Vue.set(item, "identifier", item.unit.identifier)
      Vue.set(item, "isAcknowledgedHumanReadable", item.isAcknowledged ? `${i18n.t("yes")}` : `${i18n.t("no")}`)
      Vue.set(item, "generatedDateWithOffset", Vue.prototype.$getDateWithOffset(item.generatedDate))
      Vue.set(item, "icon", Vue.prototype.$getInsightIcon(item.type))
      Vue.set(item, "iconText", Vue.prototype.$getIconText(item.type))
      Vue.set(item, "cleanText", Vue.prototype.$cleanupInsightText(item.content, item.unit.identifier))

      return item
    }

    Vue.prototype.$getInsightIcon = (type) => {
      let icon = ""
      switch (spec.SEVERITY_MAPPING[type]) {
        case 0: // threshold 2do remove stroke effect only x this icon
        case 1:
        case 2:
          icon = "vertical_align_top" // "fa-compress"
          break
        case 4: // fota
          icon = "cloud_download"
          break
        case 7: // battery
          icon = "fa-battery-quarter"
          break
        case 8: // Bist & internal err
        case 9:
          icon = "fa-bug"
          break
      }

      return icon
    }

    Vue.prototype.$getIconText = (type) => {
      let text = ""

      switch (spec.SEVERITY_MAPPING[type]) {
        case 0: // threshold 2do i118
        case 1:
        case 2:
          text = "Threshold" // "fa-compress"
          break
        case 4: // fota
          text = "Device update"
          break
        case 7: // battery
          text = "Low battery"
          break
        case 8: // Bist & internal err
        case 9:
          text = "Device error"
          break
      }

      return text
    }

    Vue.prototype.$cleanupInsightText = (text, unitId) => {
      if (text === null) return ""

      text = text.replace(` (${unitId})`, "")
      text = text.replace(` ${unitId}`, "")

      return text
    }

    // UOMS functions
    Vue.prototype.$KELVIN_CONVERSION = 273.15

    Vue.prototype.$REFERENCE_TEMPERATURE = 24

    Vue.prototype.$getConvertedValue = (value, option, dataType) => {
      let measurementSystem = store.getters.currentCompanyMeasurementSystem
      const customUoms = store.getters.customUoms
      let convertedValue = value
      let uom = ""
      let decimals = 2

      dataType = parseInt(dataType)

      const customUom = customUoms.find((cu) => Vue.prototype.$getUnifiedDataTypeId(cu.dataType) === dataType)

      if (customUom) {
        uom = customUom.destinationUom
        convertedValue = Vue.prototype.$getCustomValue(customUom, convertedValue)
      } else {
        measurementSystem = spec.MS_MAPPING[measurementSystem]

        if (measurementSystem === undefined && measurementSystem === null) measurementSystem = spec.MS_INTERNATIONAL

        switch (dataType) {
          case spec.DT_UN_TEMPERATURE:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                uom = "°C"
                decimals = 2
                break
              case spec.MS_IMPERIAL:
                uom = "°F"
                convertedValue = convertedValue * 1.8 + 32
                decimals = 2
                break
            }
            break
          case spec.DT_UN_SOUND:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "dB SPL"
                convertedValue = 20 * Math.log10(convertedValue / 0.00002)
                decimals = 5
                break
            }
            break
          case spec.DT_UN_ACCELERATION:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "g"
                decimals = 5
                break
            }
            break
          case spec.DT_UN_VOLTAGE:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "mV"
                decimals = 5
                break
            }
            break
          case spec.DT_UN_RESISTANCE:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "Ohm"
                decimals = 5
                break
            }
            break
          case spec.DT_UN_VELOCITY:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                uom = "mm/s"
                decimals = 5
                break
              case spec.MS_IMPERIAL:
                uom = "in/s"
                convertedValue *= 0.0393701
                decimals = 5
                break
            }
            break
          case spec.DT_UN_PRESSURE:
          case spec.DT_UN_COMPENSATED_PRESSURE:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                uom = "Pa"
                convertedValue *= 1000000
                decimals = 3
                break
              case spec.MS_IMPERIAL:
                uom = "psi"
                convertedValue *= 145.038
                decimals = 3
                break
            }
            break
          case spec.DT_UN_USAGE:
            uom = "%"
            break
          case spec.DT_UN_UPLOAD_DOWNLOAD_BYTES:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "kB"
                break
            }
            break
          case spec.DT_UN_RSSI:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "dBm"
                break
            }
            break
          case spec.DT_UN_UPTIME:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "s"
                break
            }
            break
          case spec.DT_UN_UPLOAD_SPEED:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "B/s"
                break
            }
            break
          case spec.DT_ELECTRIC_CURRENT:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "A"
                decimals = 5
                break
            }
            break
          case spec.DT_RELATIVE_HUMIDITY:
            uom = "%"
            decimals = 5
            break
          case spec.DT_ELECTRIC_POWER:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                uom = "W"
                decimals = 5
                break
              case spec.MS_IMPERIAL:
                uom = "hp"
                convertedValue *= 0.00134102
                decimals = 5
                break
            }
            break
          case spec.DT_UN_DISPLACEMENT:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                uom = "mm"
                decimals = 5
                break
              case spec.MS_IMPERIAL:
                uom = "in"
                convertedValue *= 0.0393701
                decimals = 5
                break
            }
            break
          case spec.DT_UN_RAW_RPM:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "∆s"
                decimals = 5
                break
            }
            break
          case spec.DT_HOURS:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "hrs."
                decimals = 2
                break
            }
            break
          case spec.DT_MINUTES:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
              case spec.MS_IMPERIAL:
                uom = "min."
                decimals = 2
                break
            }
            break
          case spec.DT_PERCENTAGE:
            uom = "%"
            break
          case spec.DT_UN_ENERGY:
            uom = "J"
            break
        }
      }

      if (option === spec.MS_ORIGINAL_VALUE_UOM) {
        convertedValue = Vue.prototype.$parseDecimal(value, decimals)
      } else {
        convertedValue = Vue.prototype.$parseDecimal(convertedValue, decimals)
      }

      switch (option) {
        case spec.MS_VALUE_UOM:
        case spec.MS_ORIGINAL_VALUE_UOM:
          return (convertedValue + " " + uom).trim()
        case spec.MS_VALUE:
          return convertedValue
        case spec.MS_UOM:
          return uom
      }
    }

    Vue.prototype.$getBaseValue = (value, dataType) => {
      let measurementSystem = store.getters.currentCompanyMeasurementSystem
      const customUoms = store.getters.customUoms
      let baseValue = value

      dataType = parseInt(dataType)

      const customUom = customUoms.find((cu) => Vue.prototype.$getUnifiedDataTypeId(cu.dataType) === dataType)

      if (customUom) {
        baseValue = Vue.prototype.$getBaseFromCustomValue(customUom, baseValue)
      } else {
        measurementSystem = spec.MS_MAPPING[measurementSystem]

        if (measurementSystem === undefined && measurementSystem === null) measurementSystem = spec.MS_INTERNATIONAL

        switch (dataType) {
          case spec.DT_UN_TEMPERATURE:
            switch (measurementSystem) {
              case spec.MS_IMPERIAL:
                baseValue = (baseValue - 32) * 0.5556
                break
            }
            break
          case spec.DT_UN_VELOCITY:
            switch (measurementSystem) {
              case spec.MS_IMPERIAL:
                baseValue /= 0.0393701
                break
            }
            break
          case spec.DT_UN_PRESSURE:
          case spec.DT_UN_COMPENSATED_PRESSURE:
            switch (measurementSystem) {
              case spec.MS_INTERNATIONAL:
                baseValue /= 1000000
                break
              case spec.MS_IMPERIAL:
                baseValue /= 145.038
                break
            }
            break
          case spec.DT_ELECTRIC_POWER:
            switch (measurementSystem) {
              case spec.MS_IMPERIAL:
                baseValue /= 0.00134102
                break
            }
            break
          case spec.DT_UN_DISPLACEMENT:
            switch (measurementSystem) {
              case spec.MS_IMPERIAL:
                baseValue /= 0.0393701
                break
            }
            break
        }
      }

      return baseValue
    }

    Vue.prototype.$getBaseUom = (dataType) => {
      let uom = ""

      switch (dataType) {
        case spec.DT_UN_TEMPERATURE:
          uom = "°C"
          break
        case spec.DT_UN_SOUND:
          uom = "dB SPL"
          break
        case spec.DT_UN_ACCELERATION:
          uom = "g"
          break
        case spec.DT_UN_VOLTAGE:
          uom = "mV"
          break
        case spec.DT_UN_RESISTANCE:
          uom = "Ohm"
          break
        case spec.DT_UN_VELOCITY:
          uom = "mm/s"
          break
        case spec.DT_UN_PRESSURE:
        case spec.DT_UN_COMPENSATED_PRESSURE:
          uom = "MPa"
          break
        case spec.DT_UN_USAGE:
          uom = "%"
          break
        case spec.DT_UN_UPLOAD_DOWNLOAD_BYTES:
          uom = "kB"
          break
        case spec.DT_UN_RSSI:
          uom = "dBm"
          break
        case spec.DT_UN_UPTIME:
          uom = "s"
          break
        case spec.DT_UN_UPLOAD_SPEED:
          uom = "B/s"
          break
        case spec.DT_ELECTRIC_CURRENT:
          uom = "A"
          break
        case spec.DT_RELATIVE_HUMIDITY:
          uom = "%"
          break
        case spec.DT_ELECTRIC_POWER:
          uom = "W"
          break
        case spec.DT_UN_DISPLACEMENT:
          uom = "mm"
          break
        case spec.DT_UN_RAW_RPM:
          uom = "∆s"
          break
        case spec.DT_UN_ENERGY:
          uom = "J"
          break
      }

      return uom
    }

    Vue.prototype.$getDataTypeV1 = (dataType) => {
      return spec.DATA_TYPE_V0_DATA_TYPE_V1[dataType]
    }

    Vue.prototype.$getCustomValue = (customUom, value) => {
      const conversionOperator = customUom.conversionOperator
      const regExp = /[a-zA-Z]/g
      let items = []

      if (conversionOperator.includes("+")) {
        items = conversionOperator.trim().split("+")
        if (regExp.test(items[0])) {
          return value + Number(items[1])
        } else {
          return value + Number(items[0])
        }
      } else if (conversionOperator.includes("*")) {
        items = conversionOperator.trim().split("*")
        if (regExp.test(items[0])) {
          return value * Number(items[1])
        } else {
          return value * Number(items[0])
        }
      } else if (conversionOperator.includes("-")) {
        items = conversionOperator.trim().split("-")
        if (regExp.test(items[0])) {
          return value - Number(items[1])
        } else {
          return Number(items[0]) - value
        }
      } else {
        items = conversionOperator.trim().split("/")
        if (regExp.test(items[0])) {
          return value / Number(items[1])
        } else {
          return Number(items[0]) / value
        }
      }
    }

    Vue.prototype.$getBaseFromCustomValue = (customUom, value) => {
      const conversionOperator = customUom.conversionOperator
      const regExp = /[a-zA-Z]/g
      let items = []

      if (conversionOperator.includes("+")) {
        items = conversionOperator.trim().split("+")
        if (regExp.test(items[0])) {
          return value - Number(items[1])
        } else {
          return Number(items[0]) - value
        }
      } else if (conversionOperator.includes("*")) {
        items = conversionOperator.trim().split("*")
        if (regExp.test(items[0])) {
          return value / Number(items[1])
        } else {
          return Number(items[0]) / value
        }
      } else if (conversionOperator.includes("-")) {
        items = conversionOperator.trim().split("-")
        if (regExp.test(items[0])) {
          return value + Number(items[1])
        } else {
          return Number(items[0]) + value
        }
      } else {
        items = conversionOperator.trim().split("/")
        if (regExp.test(items[0])) {
          return value * Number(items[1])
        } else {
          return Number(items[0]) * value
        }
      }
    }

    Vue.prototype.$getUnifiedDataTypeId = (dataTypeName) => {
      const dataType = spec.SINGLE_POINT_DATA_TYPES.find((d) => d.codeName === dataTypeName)

      if (dataType) {
        return dataType.id
      } else {
        return spec.MULTI_POINT_DATA_TYPES.find((d) => d.codeName === dataTypeName).id
      }
    }

    Vue.prototype.$getUnifiedDataTypeName = (dataTypeId) => {
      const dataType = spec.SINGLE_POINT_DATA_TYPES.find((d) => d.id === dataTypeId)

      if (dataType) {
        return dataType.codeName
      } else {
        return spec.MULTI_POINT_DATA_TYPES.find((d) => d.id === dataTypeId).codeName
      }
    }

    // Number functions
    Vue.prototype.$formatDecimal = (value, decimals = 2) => {
      if (isNaN(value)) return value
      const val = (value / 1).toFixed(decimals)
      return val
    }

    Vue.prototype.$parseDecimal = (value, decimals = 2) => {
      const val = Vue.prototype.$formatDecimal(value, decimals)
      return parseFloat(val)
    }

    Vue.prototype.$numberOrDefault = (value, defaultValue = 0) => {
      if (isNaN(value)) return defaultValue
      else return value
    }

    // String functions
    Vue.prototype.$formatEmptyText = (text) => {
      if (text === null || text.trim() === "") {
        return "-"
      }
      return text.trim()
    }

    Vue.prototype.$getDescription = (description) => {
      if (description === null) {
        return ""
      }
      return "(" + description + ")"
    }

    Vue.prototype.$stringOrDefault = (text, defaultText) => {
      if (text === undefined || text === null) {
        return defaultText
      }

      if (text instanceof String) {
        if (text.trim() === "") {
          return defaultText
        }
        return text.trim()
      }

      return defaultText
    }

    // Date functions
    Vue.prototype.$formatEmptyDate = (text) => {
      const date = Vue.prototype.$getDateWithOffset(text)
      if (date === null) {
        return "-"
      }
      return date
    }

    Vue.prototype.$hasLessMinutesThan = (minutes, date) => {
      if (date != null) {
        const now = Vue.prototype.$getDateObjectWithTimeZone()
        const diff = now.diff(moment(date), "minutes")

        return diff <= minutes
      }
      return null
    }

    Vue.prototype.$formatOnlyDate = (date) => {
      if (!date || date === "-") return "-"
      const lastUpdate = moment(date)
      return lastUpdate.format("MMMM DD, YYYY")
    }

    Vue.prototype.$fromMinutesToHours = (totalMinutes) => {
      const hours = totalMinutes / 60
      const rhours = Math.floor(hours)
      const minutes = (hours - rhours) * 60
      const rminutes = Math.round(minutes)
      return rhours + "h " + rminutes + "m"
    }

    Vue.prototype.$enumerateDaysBetweenDates = (startDate, endDate) => {
      const dates = []
      for (let date = moment(startDate); date.isBefore(endDate); date.add(1, "days")) {
        dates.push(date.toDate())
      }
      return dates
    }

    // Time Zone functions
    Vue.prototype.$timeZoneOffset = "00:00"
    Vue.prototype.$minutesTimeZoneOffset = "0"

    Vue.prototype.$formatLastUpdated = (date) => {
      if (!date || date === "-") return "-"
      const lastUpdate = moment(date)
      const currentDate = moment()
      if (lastUpdate.isSame(currentDate, "day")) return "Today, " + lastUpdate.format("HH:mm")
      else if (lastUpdate.isSame(currentDate, "month")) return lastUpdate.format("HH:mm MMMM DD")
      else return lastUpdate.format("HH:mm MMMM DD, YYYY")
    }

    Vue.prototype.$updateTimeZone = () => {
      const index = Object.keys(Utc.TZTIMEZONES).find((x) => Utc.TZTIMEZONES[x].id === store.getters.currentCompanyTimeZone)
      Vue.prototype.$timeZoneIdentifier = Utc.TZTIMEZONES[index].tz ? Utc.TZTIMEZONES[index].tz : moment.tz.guess(true)
    }

    Vue.prototype.$updateMinutesTimeZoneOffset = () => {
      const timeZoneOffset = Vue.prototype.$timeZoneOffset
      const negativeOffset = timeZoneOffset[0] === "-"
      const segments = timeZoneOffset.split(":")
      let minutes = 0

      minutes = Math.abs(segments[0]) * 60
      minutes += parseInt(segments[1])
      if (negativeOffset) {
        minutes = minutes * -1
      }
      Vue.prototype.$minutesTimeZoneOffset = String(minutes)
    }

    Vue.prototype.$getDateWithOffset = (date) => {
      if (!date || typeof date === "undefined") return null

      date += date.includes("Z") || date.includes("+") ? "" : "Z"
      return moment.tz(date, Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
    }

    Vue.prototype.$getDateWithTimeZone = () => {
      const newDate = moment().tz(Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
      return moment(newDate)
    }

    Vue.prototype.$getDateWithUtc = (date) => {
      return moment.utc(date).tz(Vue.prototype.$timeZoneIdentifier)
    }

    Vue.prototype.$getDateObjectWithTimeZone = (date) => {
      const newDate = moment.tz(date, Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
      return moment(newDate)
    }

    Vue.prototype.$lastUpdatedWithOffset = (date) => {
      if (!date || typeof date === "undefined") return null
      date += date.includes("Z") || date.includes("+") ? "" : "Z"
      const formatedDate = moment.tz(date, Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
      return Vue.prototype.$formatLastUpdated(formatedDate)
    }

    Vue.prototype.$lastUpdatedWithOffsetDate = (date) => {
      if (!date || typeof date === "undefined") return null
      const formatedDate = moment.tz(date, Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
      return Vue.prototype.$formatLastUpdated(formatedDate)
    }

    Vue.prototype.$getTimestampWithOffset = (date) => {
      if (!date || typeof date === "undefined") return null

      date += date.includes("Z") || date.includes("+") ? "" : "Z"
      const offsetDate = moment.tz(date, Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
      return moment(offsetDate).valueOf()
    }

    Vue.prototype.$convertUtcToLocalDate = (date) => {
      return moment.utc(date).tz(Vue.prototype.$timeZoneIdentifier)
    }

    Vue.prototype.$convertLocalDateToUtc = (date) => {
      return moment.tz(date, Vue.prototype.$timeZoneIdentifier).utc().format()
    }

    Vue.prototype.$convertToLocalDate = (date) => {
      return moment.tz(date, Vue.prototype.$timeZoneIdentifier)
    }

    Vue.prototype.$convertLocalDateToUtcMoment = (date) => {
      return moment.tz(date, Vue.prototype.$timeZoneIdentifier).utc()
    }

    Vue.prototype.$getlocalDate = () => {
      return moment.tz(Vue.prototype.$timeZoneIdentifier).format("YYYY-MM-DD HH:mm:ss")
    }

    Vue.prototype.$getMomentlocalDate = () => {
      return moment.tz(Vue.prototype.$timeZoneIdentifier)
    }

    // Role functions
    Vue.prototype.$canRead = (entityName) => {
      return store.getters.canRead(entityName)
    }

    Vue.prototype.$canSave = (entityName) => {
      return store.getters.canSave(entityName)
    }

    Vue.prototype.$canDelete = (entityName) => {
      return store.getters.canDelete(entityName)
    }

    Vue.prototype.$canAccess = (entityName) => {
      return store.getters.canAccess(entityName)
    }

    Vue.prototype.$systemRoleHasFeaturePermission = (permissionName) => {
      return store.getters.systemRoleHasFeaturePermission(permissionName)
    }

    Vue.prototype.$validateRoleOperation = (role) => {
      return store.getters.validateRoleOperation(role)
    }

    Vue.prototype.$validateUserOperation = (user) => {
      return store.getters.validateUserOperation(user)
    }

    Vue.prototype.$superAdminOnly = () => {
      return store.getters.superAdminOnly()
    }

    // Company Features functions
    Vue.prototype.$getCompanyFeatureStatus = (featureName) => {
      return store.getters.companyFeatureStatus(featureName)
    }

    Vue.prototype.$getEnabledDatatypes = (returnNumericDataTypes = false) => {
      return store.state.database.companyFeatures.array
        .filter(
          (feature) =>
            (feature.type === "DataAnalytics" || feature.type === "SensorReadingsChartSeries") &&
            feature.isEnabled &&
            !feature.isDeleted &&
            !feature.feature.startsWith("da_")
        )
        .map((ft) => {
          if (returnNumericDataTypes) return ft.purchasableFeatureType

          return ft.feature
        })
    }

    Vue.prototype.$isCollisionProfileCompany = () => {
      return store.getters.isCollisionProfileCompany
    }

    // Retrieve the key from a relative Location header
    Vue.prototype.$getLocationHeaderParameter = (route, index) => {
      return route.substring(1).split("/")[index]
    }

    // Aggregate functions
    Vue.prototype.$calcAggregate = (aggregateArr) => {
      const aggregateResult = {
        minimum: null,
        average: 0,
        maximum: 0,
        count: 0,
      }

      aggregateArr.forEach((agg) => {
        if ("maximum" in agg && "minimum" in agg && "average" in agg) {
          if ((aggregateResult.minimum === null && agg.minimum) || agg.minimum < aggregateResult.minimum)
            aggregateResult.minimum = agg.minimum

          if (agg.maximum > aggregateResult.maximum) aggregateResult.maximum = agg.maximum

          aggregateResult.average += agg.average
          aggregateResult.count++
        }
      })
      aggregateResult.average = Vue.prototype.$numberOrDefault(aggregateResult.average / aggregateResult.count)

      return aggregateResult
    }
  },
}
