import Vue from "vue"
import Vuex from "vuex"
import auth from "./modules/auth"
import realtime from "./modules/realtime"
import database from "./modules/database"
import components from "./modules/components"
import materialize from "./modules/materialize"
import logger from "./modules/logger"
import preferencesHandler from "./modules/preferencesHandler/index"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState],
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    realtime,
    database,
    components,
    materialize,
    logger,
    preferencesHandler,
  },
})
