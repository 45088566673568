// Source TimeZoneInfo.GetSystemTimeZones
const TZTIMEZONES = {
  0: { name: "(UTC-12:00) International Date Line West", tz: "Etc/GMT+12", id: "DatelineStandardTime" },
  1: { name: "(UTC-11:00) Coordinated Universal Time-11", tz: "Etc/GMT+11", id: "UTC_11" },
  2: { name: "(UTC-10:00) Aleutian Islands", tz: "America/Adak", id: "AleutianStandardTime" },
  3: { name: "(UTC-10:00) Hawaii", tz: "Pacific/Honolulu", id: "HawaiianStandardTime" },
  5: { name: "(UTC-09:00) Alaska", tz: "America/Yakutat", id: "AlaskanStandardTime" },
  6: { name: "(UTC-09:00) Coordinated Universal Time-09", tz: "Etc/GMT+9", id: "UTC_09" },
  7: { name: "(UTC-08:00) Baja California", tz: "America/Tijuana", id: "PacificStandardTimeMexico" },
  8: { name: "(UTC-08:00) Coordinated Universal Time-08", tz: "Etc/GMT+8", id: "UTC_08" },
  9: { name: "(UTC-08:00) Pacific Time (US & Canada)", tz: "America/Vancouver", id: "PacificStandardTime" },
  10: { name: "(UTC-07:00) Arizona", tz: "America/Phoenix", id: "USMountainStandardTime" },
  11: { name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan", tz: "America/Chihuahua", id: "MountainStandardTimeMexico" },
  12: { name: "(UTC-07:00) Mountain Time (US & Canada)", tz: "America/Denver", id: "MountainStandardTime" },
  13: { name: "(UTC-07:00) Yukon", tz: "America/Whitehorse", id: "YukonStandardTime" },
  14: { name: "(UTC-06:00) Central America", tz: "America/El_Salvador", id: "CentralAmericaStandardTime" },
  15: { name: "(UTC-06:00) Central Time (US & Canada)", tz: "America/Chicago", id: "CentralStandardTime" },
  16: { name: "(UTC-06:00) Easter Island", tz: "Pacific/Easter", id: "EasterIslandStandardTime" },
  17: { name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", tz: "America/Mexico_City", id: "CentralStandardTimeMexico" },
  18: { name: "(UTC-06:00) Saskatchewan", tz: "America/Regina", id: "CanadaCentralStandardTime" },
  19: { name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco", tz: "America/Bogota", id: "SAPacificStandardTime" },
  20: { name: "(UTC-05:00) Chetumal", tz: "America/Cancun", id: "EasternStandardTimeMexico" },
  21: { name: "(UTC-05:00) Eastern Time (US & Canada)", tz: "America/New_York", id: "EasternStandardTime" },
  22: { name: "(UTC-05:00) Haiti", tz: "America/Port-au-Prince", id: "HaitiStandardTime" },
  23: { name: "(UTC-05:00) Havana", tz: "America/Havana", id: "CubaStandardTime" },
  24: { name: "(UTC-05:00) Indiana (East)", tz: "America/Indiana/Indianapolis", id: "USEasternStandardTime" },
  25: { name: "(UTC-05:00) Turks and Caicos", tz: "America/Grand_Turk", id: "TurksAndCaicosStandardTime" },
  26: { name: "(UTC-04:00) Asuncion", tz: "America/Asuncion", id: "ParaguayStandardTime" },
  27: { name: "(UTC-04:00) Atlantic Time (Canada)", tz: "America/Halifax", id: "AtlanticStandardTime" },
  28: { name: "(UTC-04:00) Caracas", tz: "America/Caracas", id: "VenezuelaStandardTime" },
  29: { name: "(UTC-04:00) Cuiaba", tz: "America/Cuiaba", id: "CentralBrazilianStandardTime" },
  30: { name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan", tz: "America/Manaus", id: "SAWesternStandardTime" },
  31: { name: "(UTC-04:00) Santiago", tz: "America/Santiago", id: "PacificSAStandardTime" },
  33: { name: "(UTC-03:00) Araguaina", tz: "America/Araguaina", id: "TocantinsStandardTime" },
  34: { name: "(UTC-03:00) Brasilia", tz: "America/Sao_Paulo", id: "ESouthAmericaStandardTime" },
  35: { name: "(UTC-03:00) Cayenne, Fortaleza", tz: "America/Cayenne", id: "SAEasternStandardTime" },
  36: { name: "(UTC-03:00) City of Buenos Aires", tz: "America/Argentina/Buenos_Aires", id: "ArgentinaStandardTime" },
  42: { name: "(UTC-03:00) Greenland", tz: "America/Nuuk", id: "GreenlandStandardTime" },
  37: { name: "(UTC-03:00) Montevideo", tz: "America/Montevideo", id: "MontevideoStandardTime" },
  38: { name: "(UTC-03:00) Punta Arenas", tz: "America/Punta_Arenas", id: "MagallanesStandardTime" },
  39: { name: "(UTC-03:00) Saint Pierre and Miquelon", tz: "America/Miquelon", id: "SaintPierreStandardTime" },
  40: { name: "(UTC-03:00) Salvador", tz: "America/Bahia", id: "BahiaStandardTime" },
  41: { name: "(UTC-02:00) Coordinated Universal Time-02", tz: "Etc/GMT+2", id: "UTC_02" },
  43: { name: "(UTC-02:00) Mtz-Atlantic - Old", tz: "America/Noronha", id: "Mid_AtlanticStandardTime" },
  44: { name: "(UTC-01:00) Azores", tz: "Atlantic/Azores", id: "AzoresStandardTime" },
  45: { name: "(UTC-01:00) Cabo Verde Is.", tz: "Atlantic/Cape_Verde", id: "CapeVerdeStandardTime" },
  46: { name: "(UTC) Coordinated Universal Time", tz: "UTC", id: "UTC" },
  47: { name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", tz: "Europe/London", id: "GMTStandardTime" },
  48: { name: "(UTC+00:00) Monrovia, Reykjavik", tz: "Africa/Monrovia", id: "GreenwichStandardTime" },
  49: { name: "(UTC+00:00) Sao Tome", tz: "Africa/Sao_Tome", id: "SaoTomeStandardTime" },
  50: { name: "(UTC+01:00) Casablanca", tz: "Africa/Casablanca", id: "MoroccoStandardTime" },
  51: { name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", tz: "Europe/Amsterdam", id: "WEuropeStandardTime" },
  52: {
    name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    tz: "Europe/Belgrade",
    id: "CentralEuropeStandardTime",
  },
  53: { name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", tz: "Europe/Brussels", id: "RomanceStandardTime" },
  54: { name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", tz: "Europe/Sarajevo", id: "CentralEuropeanStandardTime" },
  55: { name: "(UTC+01:00) West Central Africa", tz: "Africa/Lagos", id: "WCentralAfricaStandardTime" },
  69: { name: "(UTC+02:00) Amman", tz: "Asia/Amman", id: "JordanStandardTime" },
  56: { name: "(UTC+02:00) Athens, Bucharest", tz: "Europe/Athens", id: "GTBStandardTime" },
  57: { name: "(UTC+02:00) Beirut", tz: "Asia/Beirut", id: "MiddleEastStandardTime" },
  58: { name: "(UTC+02:00) Cairo", tz: "Africa/Cairo", id: "EgyptStandardTime" },
  59: { name: "(UTC+02:00) Chisinau", tz: "Europe/Chisinau", id: "EEuropeStandardTime" },
  60: { name: "(UTC+02:00) Gaza, Hebron", tz: "Asia/Gaza", id: "WestBankStandardTime" },
  61: { name: "(UTC+02:00) Harare, Pretoria", tz: "Africa/Maputo", id: "SouthAfricaStandardTime" },
  62: { name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", tz: "Europe/Helsinki", id: "FLEStandardTime" },
  63: { name: "(UTC+02:00) Jerusalem", tz: "Asia/Jerusalem", id: "IsraelStandardTime" },
  65: { name: "(UTC+02:00) Kaliningrad", tz: "Europe/Kaliningrad", id: "KaliningradStandardTime" },
  66: { name: "(UTC+02:00) Khartoum", tz: "Africa/Khartoum", id: "SudanStandardTime" },
  67: { name: "(UTC+02:00) Tripoli", tz: "Africa/Tripoli", id: "LibyaStandardTime" },
  68: { name: "(UTC+02:00) Windhoek", tz: "Africa/Windhoek", id: "NamibiaStandardTime" },
  70: { name: "(UTC+03:00) Baghdad", tz: "Asia/Baghdad", id: "ArabicStandardTime" },
  71: { name: "(UTC+03:00) Damascus", tz: "Asia/Syria", id: "SyriaStandardTime" },
  72: { name: "(UTC+03:00) Istanbul", tz: "Europe/Istanbul", id: "TurkeyStandardTime" },
  73: { name: "(UTC+03:00) Kuwait, Riyadh", tz: "Asia/Riyadh", id: "ArabStandardTime" },
  74: { name: "(UTC+03:00) Minsk", tz: "Europe/Minsk", id: "BelarusStandardTime" },
  75: { name: "(UTC+03:00) Moscow, St. Petersburg", tz: "Europe/Moscow", id: "RussianStandardTime" },
  76: { name: "(UTC+03:00) Nairobi", tz: "Africa/Nairobi", id: "EAfricaStandardTime" },
  79: { name: "(UTC+04:00) Abu Dhabi, Muscat", tz: "Asia/Dubai", id: "ArabianStandardTime" },
  80: { name: "(UTC+04:00) Astrakhan, Ulyanovsk", tz: "Europe/Astrakhan", id: "AstrakhanStandardTime" },
  81: { name: "(UTC+04:00) Baku", tz: "Asia/Baku", id: "AzerbaijanStandardTime" },
  82: { name: "(UTC+04:00) Izhevsk, Samara", tz: "Europe/Samara", id: "RussiaTimeZone3" },
  83: { name: "(UTC+04:00) Port Louis", tz: "Indian/Mauritius", id: "MauritiusStandardTime" },
  84: { name: "(UTC+04:00) Saratov", tz: "Europe/Saratov", id: "SaratovStandardTime" },
  85: { name: "(UTC+04:00) Tbilisi", tz: "Asia/Tbilisi", id: "GeorgianStandardTime" },
  77: { name: "(UTC+04:00) Volgograd", tz: "Europe/Volgograd", id: "VolgogradStandardTime" },
  86: { name: "(UTC+04:00) Yerevan", tz: "Asia/Yerevan", id: "CaucasusStandardTime" },
  88: { name: "(UTC+05:00) Ashgabat, Tashkent", tz: "Asia/Ashgabat", id: "WestAsiaStandardTime" },
  90: { name: "(UTC+05:00) Ekaterinburg", tz: "Asia/Yekaterinburg", id: "EkaterinburgStandardTime" },
  91: { name: "(UTC+05:00) Islamabad, Karachi", tz: "Asia/Karachi", id: "PakistanStandardTime" },
  89: { name: "(UTC+05:00) Qyzylorda", tz: "Asia/Qyzylorda", id: "QyzylordaStandardTime" },
  95: { name: "(UTC+06:00) Astana", tz: "Asia/Almaty", id: "CentralAsiaStandardTime" },
  96: { name: "(UTC+06:00) Dhaka", tz: "Asia/Dhaka", id: "BangladeshStandardTime" },
  97: { name: "(UTC+06:00) Omsk", tz: "Asia/Omsk", id: "OmskStandardTime" },
  99: { name: "(UTC+07:00) Bangkok, Hanoi, Jakarta", tz: "Asia/Bangkok", id: "SEAsiaStandardTime" },
  100: { name: "(UTC+07:00) Barnaul, Gorno-Altaysk", tz: "Asia/Barnaul", id: "AltaiStandardTime" },
  101: { name: "(UTC+07:00) Hovd", tz: "Asia/Hovd", id: "WMongoliaStandardTime" },
  102: { name: "(UTC+07:00) Krasnoyarsk", tz: "Asia/Krasnoyarsk", id: "NorthAsiaStandardTime" },
  103: { name: "(UTC+07:00) Novosibirsk", tz: "Asia/Novosibirsk", id: "NCentralAsiaStandardTime" },
  104: { name: "(UTC+07:00) Tomsk", tz: "Asia/Tomsk", id: "TomskStandardTime" },
  105: { name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", tz: "Asia/Shanghai", id: "ChinaStandardTime" },
  106: { name: "(UTC+08:00) Irkutsk", tz: "Asia/Irkutsk", id: "NorthAsiaEastStandardTime" },
  107: { name: "(UTC+08:00) Kuala Lumpur, Singapore", tz: "Asia/Singapore", id: "SingaporeStandardTime" },
  108: { name: "(UTC+08:00) Perth", tz: "Australia/Perth", id: "WAustraliaStandardTime" },
  109: { name: "(UTC+08:00) Taipei", tz: "Asia/Taipei", id: "TaipeiStandardTime" },
  110: { name: "(UTC+08:00) Ulaanbaatar", tz: "Asia/Ulaanbaatar", id: "UlaanbaatarStandardTime" },
  112: { name: "(UTC+09:00) Chita", tz: "Asia/Chita", id: "TransbaikalStandardTime" },
  113: { name: "(UTC+09:00) Osaka, Sapporo, Tokyo", tz: "Asia/Tokyo", id: "TokyoStandardTime" },
  114: { name: "(UTC+09:00) Pyongyang", tz: "Asia/Pyongyang", id: "NorthKoreaStandardTime" },
  115: { name: "(UTC+09:00) Seoul", tz: "Asia/Seoul", id: "KoreaStandardTime" },
  116: { name: "(UTC+09:00) Yakutsk", tz: "Asia/Yakutsk", id: "YakutskStandardTime" },
  120: { name: "(UTC+10:00) Brisbane, Canberra, Melbourne, Sydney", tz: "Australia/Sydney", id: "AUSEasternStandardTime" },
  121: { name: "(UTC+10:00) Guam, Port Moresby", tz: "Pacific/Guam", id: "WestPacificStandardTime" },
  122: { name: "(UTC+10:00) Hobart", tz: "Australia/Hobart", id: "TasmaniaStandardTime" },
  123: { name: "(UTC+10:00) Vladivostok", tz: "Asia/Vladivostok", id: "VladivostokStandardTime" },
  125: { name: "(UTC+11:00) Bougainville Island", tz: "Pacific/Bougainville", id: "BougainvilleStandardTime" },
  126: { name: "(UTC+11:00) Chokurdakh", tz: "Asia/Srednekolymsk", id: "RussiaTimeZone10" },
  127: { name: "(UTC+11:00) Magadan", tz: "Asia/Magadan", id: "MagadanStandardTime" },
  128: { name: "(UTC+11:00) Norfolk Island", tz: "Pacific/Norfolk", id: "NorfolkStandardTime" },
  129: { name: "(UTC+11:00) Sakhalin", tz: "Asia/Sakhalin", id: "SakhalinStandardTime" },
  130: { name: "(UTC+11:00) Solomon Is., New Caledonia", tz: "Pacific/Noumea", id: "CentralPacificStandardTime" },
  131: { name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky", tz: "Asia/Anadyr", id: "RussiaTimeZone11" },
  132: { name: "(UTC+12:00) Auckland, Wellington", tz: "Pacific/Auckland", id: "NewZealandStandardTime" },
  133: { name: "(UTC+12:00) Coordinated Universal Time+12", tz: "Etc/GMT-12", id: "UTC12" },
  134: { name: "(UTC+12:00) Fiji", tz: "Pacific/Fiji", id: "FijiStandardTime" },
  135: { name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old", tz: "Asia/Kamchatka", id: "KamchatkaStandardTime" },
  137: { name: "(UTC+13:00) Coordinated Universal Time+13", tz: "Etc/GMT-13", id: "UTC13" },
  138: { name: "(UTC+13:00) Nuku'alofa", tz: "Pacific / Tongatapu", id: "TongaStandardTime" },
  139: { name: "(UTC+13:00) Samoa", tz: "Pacific/Apia", id: "SamoaStandardTime" },
  140: { name: "(UTC+14:00) Kiritimati Island", tz: "Pacific/Kiritimati", id: "LineIslandsStandardTime" },
}

export default {
  TZTIMEZONES,
}
