import api from "../../../api"

const retrievePreferencesSet = (context) => {
  api.preferencesSet
    .getByUserId({
      _id: context.rootGetters.getUserEntityKey,
    })
    .then((res) => {
      syncSets(context, res.data)
      context.commit("markPreferencesAdLoaded")
    })
    .catch((error) => {
      context.dispatch("logErrorMessage", { error, display: true })
    })
}

const saveCurrent = (context) => {
  api.preferencesSet
    .update(context.getters.getSelectedPreferencesSet)
    .then((res) => {
      syncSets(context, res.data)
    })
    .catch((error) => {
      context.dispatch("logErrorMessage", { error, display: true })
    })
}

const saveNew = (context, setData) => {
  api.preferencesSet
    .create(setData)
    .then((res) => {
      syncSets(context, res.data)
    })
    .catch((error) => {
      context.dispatch("logErrorMessage", { error, display: true })
    })
}

const syncSets = (context, sets) => {
  context.commit("setPreferencesSets", sets)
}

const createNew = (context, name) => {
  api.preferencesSet
    .getEmpty()
    .then((res) => {
      res.data.name = name
      context.commit("addPreferencesSets", res.data)
      saveNew(context, res.data)
    })
    .catch((error) => {
      context.dispatch("logErrorMessage", { error, display: true })
    })
}

const selectPreferences = (context, preferencesSetEntityKey) => {
  api.preferencesSet
    .select({ _id: preferencesSetEntityKey })
    .then((res) => {
      syncSets(context, res.data)
    })
    .catch((error) => {
      context.dispatch("logErrorMessage", { error, display: true })
    })
}

export default {
  retrievePreferencesSet,
  saveCurrent,
  saveNew,
  syncSets,
  createNew,
  selectPreferences,
}
